import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";
import { withTranslation } from 'react-i18next';
import moment from "moment";

import * as formats from '../../utilities/formats';
import validate from '../../utilities/validation-rules';

import TextInput from '../../elements/form-input-text';
import CartRowExplanation from './cart-row-explanation';
import OrderItem from './order-item';

const OrderRow = inject("stores") (
    observer (
        class OrderRow extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_PUBLIC;
                this.urlInvoice = process.env.REACT_APP_URL_INVOICES;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeOrders = this.props.stores.storeOrders;

                this.state = {
                    order: {}
                }
            }

            componentDidMount = async() => {
                const order = await this.storeOrders.getOrder(this.props.order.orderId);

                let paymentStatus = '';
                if (this.props.order.orderStatus === 'paymentCompleted') {
                    paymentStatus = '--closed';
                } else {
                    paymentStatus = '--open';
                }

                this.setState({
                    order: order,
                    paymentStatus: paymentStatus
                })
            }
            
            componentDidUpdate = async(prevProps) => {
                if (prevProps.orderId !== this.props.orderId) {
                    const order = await this.storeOrders.getOrder(this.props.orderId);
    
                    let paymentStatus = '';
                    if (this.props.order.orderStatus === 'paymentCompleted') {
                        paymentStatus = '--closed';
                    } else {
                        paymentStatus = '--open';
                    }
    
                    this.setState({
                        order: order,
                        paymentStatus: paymentStatus
                    })
                }
            }

            formatDate = (datetime) => {
                const date = datetime.date;
                const fdate = moment(date).format('LL');

                return fdate;
            }

            viewPdf = () => {
                const url = this.urlInvoice + "factuur-" + this.props.order.orderGUID + ".pdf";
                window.open(url);
            }

            render() {
                return (                  
                    <div
                        className = "wrapper-order"
                    >
                        <div
                            className = "order order__meta"
                        >
                            <span>Order nr: {this.props.order.orderId}</span>
                            <span>{this.formatDate(this.props.order.orderDate)}</span>
                        </div>
                        <div
                            className = "order order__data"
                        >
                            {this.state.order.length > 0 && this.state.order.map((item) => (
                                <OrderItem
                                    item = {item}
                                />
                            ))}
                        </div>
                        <div
                            className = "order order__totals"
                        >
                            <div
                                className = "order order__total"
                            >
                                <span
                                    className = "total__description"
                                >
                                    Order subtotal:
                                </span>
                                <span
                                    className = "total__amount"
                                >
                                    {this.props.order.orderSubtotal}
                                </span>
                            </div>
                            <div
                                className = "order order__total"
                            >
                                <span
                                    className = "total__description"
                                >
                                    Shipping:
                                </span>
                                <span
                                    className = "total__amount"
                                >
                                    {this.props.order.shipping}
                                </span>
                            </div>
                            <div
                                className = "order order__total order order__total--ordertotal"
                            >
                                <span
                                    className = "total__description"
                                >
                                    Order total:
                                </span>
                                <span
                                    className = "total__amount"
                                >
                                    {this.props.order.orderTotal}
                                </span>
                            </div>
                            <div
                                className = "order order__total total"
                            >
                                <span
                                    className = "total__description"
                                >
                                    VAT (21%):
                                </span>
                                <span
                                    className = "total__amount"
                                >
                                    {this.props.order.orderVAT}
                                </span>
                            </div>
                        </div>

                        <div
                            className = "order order__feedback"
                        >
                            {this.state.paymentStatus === '--open' &&
                                <span
                                    className = {"order order__payment " + this.state.paymentStatus}
                                >
                                    {i18n.t("order_feedback.order_payment_open")}
                                </span>
                            }
                            {this.state.paymentStatus === '--closed' &&
                                <span
                                    className = {"order order__payment " + this.state.paymentStatus}
                                >
                                    {i18n.t("order_feedback.order_payment_closed")}
                                </span>
                            }
                            <span
                                className = "order order__download --link"
                                onClick = {() => this.viewPdf()}
                            >
                                View/Download pdf
                            </span>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(OrderRow));
