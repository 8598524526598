import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import Template from '../templates/template';

const About = inject("stores") (
    observer (
        class About extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    redirectISBN: false,
                    redirectPayment: false,
                    id_quote: 0
                }
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storePageContent = this.props.stores.storePageContent;
            }

            componentDidMount = async() => {
                const page = "about";
                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);

                this.setState({
                    title: title,
                    blocks: blocks
                })
            }

            componentDidUpdate = () => {
               
            }

            

            render() {

                return (                  
                    <Template
                        type = 'default'
                        title = {this.state.title && this.state.title}
                    >   
                        <React.Fragment>
                            <div
                                className = "wrapper-content"
                            >
                                {this.state.blocks && this.state.blocks.map((block, i) => (
                                    <div
                                        key = {i}
                                    >
                                        <p
                                             dangerouslySetInnerHTML={{ __html: block.textEN}}
                                        />
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(About));
