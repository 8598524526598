import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import Template from '../../templates/template';

const Error404 = inject("stores") (
    observer (
        class Error404 extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storePageContent = this.props.stores.storePageContent;

                this.state = {

                }
            }

            componentDidMount = async() => {
                const page = "error404";
                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);

                this.setState({
                    title: title,
                    blocks: blocks
                })
            }
    
            render() {
                return (
                    <Template
                        type = {'error'}
                        title = {this.state.title && this.state.title}
                    >
                        <div
                            className = "wrapper-content"
                        >
                            {this.state.blocks && this.state.blocks.map((block, i) => (
                                <div
                                    key = {i}
                                >
                                    <p
                                        dangerouslySetInnerHTML={{ __html: block.textEN}}
                                    />
                                </div>
                            ))}
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default  withTranslation()(Error404);
