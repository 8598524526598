import React, { Component } from 'react';
import { inject, observer} from "mobx-react";
import { withRouter } from "../../withRouter";
//import { withRouter } from 'react-router-dom';

import * as formats from '../../utilities/formats';

const CartSummary = inject("stores") (
    observer (
        class CartSummary extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUser = this.props.stores.storeUser;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeCart = this.props.stores.storeCart;

                this.state = {
                    total: 0,
                    nrItems: this.storeCart.nrItems,
                    amount: this.props.amount
                }
            }

            componentDidMount = async() => {
                let cart;
                let nrItems;

                const cartId = this.storeCart.cartId;
                cart = await this.storeCart.getCartItems(cartId);
                const total =  formats.displayPrice(this.storeCart.calcTotal());
                nrItems = this.storeCart.calcNrItems;

                this.setState({
                    total: total,
                    nrItems: nrItems
                })
            }
    
            componentDidUpdate = async(prevProps) => {
                const cartId = this.storeCart.cartId;
                let newCart;
                let discount;

                if (prevProps.nrItems !== this.props.nrItems) {
                    const result = await this.calcTotal();
                    const total =  formats.displayPrice(this.storeCart.calcTotal());
                    const nrItems = this.storeCart.calcNrItems;

                    this.setState({
                        total: total,
                        nrItems: nrItems
                    })
                }

                if (prevProps.cartId !== this.props.cartId) {
                    const cart = await this.storeCart.getCartItems(this.props.cartId);
                    const total =  formats.displayPrice(this.storeCart.calcTotal());
                    const nrItems = this.storeCart.calcNrItems;

                    this.setState({
                        total: total,
                        nrItems: nrItems
                    })
                }

                if (prevProps.total !== this.props.total) {   
                    const result = await this.calcTotal();
                    const total =  formats.displayPrice(this.storeCart.calcTotal());
                    this.setState({
                        total: total
                    })
                }

                if (prevProps.isReseller !== this.props.isReseller) { 
                    const result = await this.calcTotal();
                    const total =  formats.displayPrice(this.storeCart.calcTotal());

                    this.setState({
                        total: total
                    })
                }
            }

            calcTotal = async() => {
                let discount;
                let newCart

                if (this.storeCart.cart && this.storeCart.cart.length > 0) {
                    for (const item of this.storeCart.cart) {
                        const product = await this.storeProducts.getProduct(item.sku);

                        if (parseInt(this.props.isReseller) === 1) {
                            if (item.amount >= product.discountWholesaleMinAmount) {
                                discount = product.discountWholesale;
                            } else {
                                discount = product.discount
                            }
                        } else {
                            discount = product.discount;
                        }
                        newCart = await this.storeCart.updateCart(item.sku, item.amount, discount);
                    }
                }
            }

            getUser = async() => {
                const id = this.storeAuth.user.id;
                const email = this.storeAuth.user.email;
                const user = await this.storeUser.getUserData(id, email);

                return user;
            }

            gotoPage = (page) => {
                const url = "/" + page;
                this.props.navigate(url);
            }
       
            render() {             
                return (
                    <div
                        key = {this.props.key}
                        className = "wrapper-cart-summary --link"
                        onClick = {() => this.gotoPage('cart')}
                    >
                        <div
                            className = "wrapper-cart-summary__amount"
                        >
                            {this.storeCart.cart && this.storeCart.cart.length === 0
                            ?   <span>0,00</span>
                            :   <span>
                                    {this.state.total}
                                </span>
                            }
                        </div>
                        <div
                            className = "wrapper-cart-summary__items"
                        >
                            {this.state.nrItems === 1
                            ?   <span>1 item</span>
                            :   <span>{this.state.nrItems} items</span>
                            }
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(CartSummary);
