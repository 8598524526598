import React from 'react';

const RadioMethod = props => {

    let formControl = "form-control";
    let direction = "--" + props.direction;

    return (
        <div className={"form-group form-group--radio " + direction}>
            {props.options.map(option => (
                <React.Fragment>
                    <div className="form-group__option" key={option.value}>
                        <label>
                            {option.displayValue}
                            {(props.selected === 'BANKTRANS' && option.id === 'BANKTRANS') &&
                                // <span className="form-group--radio__description">LET OP: Klik hieronder op 'Rond je bestelling af' om de betalingsinformatie voor de overschrijving te ontvangen.</span>
                                <span className="form-group--radio__description">Click on 'Complete order' below to finish your order and receive the payment details for a bank transfer.</span>
                            }
                            {(props.selected === 'IDEAL' && option.id === 'IDEAL') &&
                                // <span className="form-group--radio__description">Kies hieronder je bank en klik daarna op 'Start betaling' om het betaalproces te starten.</span>
                                <span className="form-group--radio__description">Choose your bank below and click on 'Start payment' to start the payment process.</span>
                            }
                        </label>
                        <input type="radio"
                            name = {props.name}
                            value = {option.id}
                            onChange = {props.onChange}
                            className = {formControl}
                            defaultChecked = {props.defaultValue === option.id ? 'defaultChecked' : null}
                        />
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
}

export default RadioMethod;