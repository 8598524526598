import * as apifetch from './fetch.js';

export async function checkEmailExists(email) {
    const urlEndpoint = '/v2/accounts/check-email';

    const postData = {
        email: email
    }

    return apifetch.abandaFetch(urlEndpoint, 'POST', postData);
}

export async function addUser(formControls, form) {
    const urlEndpoint = '/v2/accounts/register';

    let postData;
    let reseller = false;
    if (form === 'register') {
        postData = {
            form: form,
            email: formControls.email.value,
            pw: formControls.password.value,
            firstname: formControls.firstname.value,
            middlename: formControls.middlename.value,
            lastname: formControls.lastname.value,
            phonenr: formControls.phonenr.value,
            country: formControls.country_select.value,
            countryCode: formControls.country_select.value,
            reseller: false
        };
    }

    if (form === 'register-wholesale') {
        postData = {
            form: form,
            email: formControls.email.value,
            pw: formControls.password.value,
            firstname: formControls.firstname.value,
            middlename: formControls.middlename.value,
            lastname: formControls.lastname.value,
            phonenr: formControls.phonenr.value,
            company: formControls.company.value,
            reseller: 0,
            approved: 2,
            address: formControls.address.value,
            housenr: formControls.housenr.value,
            addition: formControls.addition.value,
            zipcode: formControls.zipcode.value,
            city: formControls.city.value,
            country: formControls.country_select.value,
            countryCode: formControls.country_select.value
        };
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function getUser(id, email) {
    const urlEndpoint = '/v2/accounts/user';

    const postData = {
        id: id,
        email: email
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function updateUser(userId, formControls, country, form) {
    const urlEndpoint = '/v2/accounts/update';

    let postData;
    let reseller = false;
    if (form === 'account') {
        postData = {
            form: form,
            id: userId,
            email: formControls.invoiceEmail.value,
            pw: formControls.password.value,
            firstname: formControls.invoiceFirstname.value,
            middlename: formControls.invoiceMiddlename.value,
            lastname: formControls.invoiceLastname.value,
            phonenr: formControls.invoicePhonenr.value,
            country: country,
            countryCode: formControls.invoiceCountry_select.value,
            reseller: false
        };
    }

    if (form === 'register-wholesale') {
        postData = {
            form: form,
            id: userId,
            email: formControls.invoiceEmail.value,
            pw: formControls.password.value,
            firstname: formControls.invoiceFirstname.value,
            middlename: formControls.invoiceMiddlename.value,
            lastname: formControls.invoiceLastname.value,
            phonenr: formControls.invoicePhonenr.value,
            company: formControls.invoiceCompany.value,
            vatnr: formControls.invoiceVATnr.value,
            address: formControls.invoiceAddress.value,
            housenr: formControls.invoiceHousenr.value,
            addition: formControls.invoiceAddition.value,
            zipcode: formControls.invoiceZipcode.value,
            city: formControls.invoiceCity.value,
            country: country,
            countryCode: formControls.invoiceCountry_select.value,
            reseller: form === 'register-wholesale' 
                ?   0 
                :   99,
            approved: form === 'register-wholesale' 
                ?   2 
                :   99
        };
    }

    if (form === 'accountupdate') {
        postData = {
            form: form,
            id: userId,
            email: formControls.invoiceEmail.value,
            pw: ('password' in formControls) 
                ?   formControls.password.value
                :   '',
            firstname: formControls.invoiceFirstname.value,
            middlename: formControls.invoiceMiddlename.value,
            lastname: formControls.invoiceLastname.value,
            phonenr: formControls.invoicePhonenr.value,
            company: formControls.invoiceCompany.value,
            vatnr: ('vatnr' in formControls) 
                ?   formControls.vatnr.value
                :   0,
            address: formControls.invoiceAddress.value,
            housenr: formControls.invoiceHousenr.value,
            addition: formControls.invoiceAddition.value,
            zipcode: formControls.invoiceZipcode.value,
            city: formControls.invoiceCity.value,
            country: country,
            countryCode: formControls.invoiceCountry_select.value,
            reseller: form === 'register-wholesale' 
                ?   0 
                :   99,
            approved: form === 'register-wholesale' 
                ?   2 
                :   99
        };
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

// export async function addUser(formControls, form) {
//     const urlEndpoint = '/v2/accounts/register';

//     console.log('SbC addUser', formControls, form)

//     let postData;
//     let reseller = false;
//     if (form === 'register') {
//         postData = {
//             form: form,
//             email: formControls.invoiceEmail.value,
//             pw: formControls.password.value,
//             firstname: formControls.invoiceFirstname.value,
//             middlename: formControls.invoiceMiddlename.value,
//             lastname: formControls.invoiceLastname.value,
//             phonenr: formControls.invoicePhonenr.value,
//             country: 'Nederland',
//             countryCode: 'NL',
//             reseller: false
//         };
//     }

//     if (form === 'register-wholesale') {
//         postData = {
//             form: form,
//             email: formControls.invoiceEmail.value,
//             pw: formControls.password.value,
//             firstname: formControls.invoiceFirstname.value,
//             middlename: formControls.invoiceMiddlename.value,
//             lastname: formControls.invoiceLastname.value,
//             phonenr: formControls.invoicePhonenr.value,
//             company: formControls.invoiceCompany.value,
//             reseller: 0,
//             approved: 2,
//             address: formControls.invoiceAddress.value,
//             housenr: formControls.invoiceHousenr.value,
//             addition: formControls.invoiceAddition.value,
//             zipcode: formControls.invoiceZipcode.value,
//             city: formControls.invoiceCity.value,
//             country: formControls.invoiceCountry_select.value,
//             countryCode: formControls.invoiceCountry_select.value
//         };
//     }

//     console.log('SbC postdata', postData)

//     return apifetch.authFetch(urlEndpoint, 'POST', postData);
// }

// export async function updateUser(userId, formControls, country, form) {
//     const urlEndpoint = '/v2/accounts/update';

//     let postData;
//     let reseller = false;
//     if (form === 'account') {
//         postData = {
//             form: form,
//             id: userId,
//             email: formControls.invoiceEmail.value,
//             pw: formControls.password.value,
//             firstname: formControls.invoiceFirstname.value,
//             middlename: formControls.invoiceMiddlename.value,
//             lastname: formControls.invoiceLastname.value,
//             phonenr: formControls.invoicePhonenr.value,
//             country: country,
//             countryCode: formControls.invoiceCountry_select.value,
//             reseller: false
//         };
//     }

//     if (form === 'register-wholesale' || form === 'accountupdate') {
//         postData = {
//             form: form,
//             id: userId,
//             email: formControls.invoiceEmail.value,
//             pw: formControls.password.value,
//             firstname: formControls.invoiceFirstname.value,
//             middlename: formControls.invoiceMiddlename.value,
//             lastname: formControls.invoiceLastname.value,
//             phonenr: formControls.invoicePhonenr.value,
//             company: formControls.invoiceCompany.value,
//             address: formControls.invoiceAddress.value,
//             housenr: formControls.invoiceHousenr.value,
//             addition: formControls.invoiceAddition.value,
//             zipcode: formControls.invoiceZipcode.value,
//             city: formControls.invoiceCity.value,
//             country: country,
//             countryCode: formControls.invoiceCountry_select.value,
//             reseller: form === 'register-wholesale' 
//                 ?   0 
//                 :   99,
//             approved: form === 'register-wholesale' 
//                 ?   2 
//                 :   99
//         };
//     }

//     console.log('SbC post account', postData)

//     return apifetch.authFetch(urlEndpoint, 'POST', postData);
// }