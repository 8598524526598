import React, { Component }  from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";

import ButtonDialog from '../../elements/button-dialog';
import Checkbox from '../../elements/form-input-checkbox';

const DialogVerification = inject("stores") (
    observer (
        class DialogVerification extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storePageContent = this.props.stores.storePageContent;

                this.state = {
                    blocks: [],
                    formControls: {
                        set_message: {
                            label: i18n.t("forms.label.subscribe"),
                            value: 1,
                            validationRules: {},
                            valid: true
                        }
                    }
                }
            }

            componentDidMount = async() => {
                document.addEventListener("keydown", this.escFunction, false);

                const page = "dialogverification";
                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);

                this.setState({
                    title: title,
                    blocks: blocks
                })
            }
            
            componentWillUnmount = () => {
                document.removeEventListener("keydown", this.escFunction, false);
            }

            escFunction = (event) => {
                if (event.keyCode === 27) {
                    this.props.onClose();
                }
            }

            handleClose = (e) => {
                this.setMsgVerification();
                this.props.onClose();
            }

            handleCheckboxChange = (event) => {
                const name = 'set_message';

                this.setState({
                    formControls: {
                        ...this.state.formControls,
                        [name]: {
                            ...this.state.formControls[name],
                            value: (parseInt(this.state.formControls[name].value) === 1) ? 0 : 1,
                            selected: (parseInt(this.state.formControls[name].value) === 1) ? 0 : 1
                        }
                    }
                    
                });
            }

            setMsgVerification = () => {
                let value;
                if (parseInt(this.state.formControls.set_message.value) === 1) {
                    value = 0;
                } else {
                    value = 1;
                }
                this.storeUi.setMsgVerification(value);
            }

            render() {
                if(!this.props.show) {
                    return null;
                }

                let dialogClass = "dialog--content";

                return (
                    <div className={"dialog " + dialogClass}>
                        <div className="dialog-header">
                            <h2>{this.state.title && this.state.title}</h2>
                        </div>
                        <div className="dialog-content">
                            {this.state.blocks && this.state.blocks.map((block, i) => (
                                <div
                                    key = {i}
                                >
                                    <p
                                        dangerouslySetInnerHTML={{ __html: block.textEN}}
                                    />
                                </div>
                            ))}
                            <div>
                                <Checkbox 
                                    name = {'set_message'} 
                                    label = {<span
                                            className="dialog-content__label--checkbox --link"
                                            onClick={() => this.setMsgVerification()}
                                        >
                                            Do not show this message again
                                        </span>
                                    }
                                    value = {this.state.formControls['set_message'].value}
                                    isSelected = {this.state.formControls['set_message'].value}
                                    onCheckboxChange={this.handleCheckboxChange}
                                    className = {"--reduced --subscription"}
                                />
                            </div>
                            
                        </div>
                        {this.props.buttons &&
                            <div className="dialog-footer">
                                {this.props.buttons.map((button, i) => (
                                    <ButtonDialog
                                        key = {i}
                                        caption = {button.text}
                                        onAction = {this.handleClose}
                                        btnClass = {button.btnClass}
                                    />
                                ))}
                            </div>
                        }
                    </div>
                    
                );
            }
        }
    )
)

export default withRouter(DialogVerification);
