import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import Template from '../../templates/template';
import CartRow from './cart-row';
import CartTotals from './cart-totals';

const CartRowExplanation = inject("stores") (
    observer (
        class CartRowExplanation extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeDiscount = this.props.stores.storeDiscount;

                this.state = {
                    discountsOverruled: false,
                    discount: 0,
                    feedback: ''
                };
            }

            componentDidMount = async() => {
                const accountId = this.storeAuth.user.userId;

                if (this.props.product) {
                    const discountsOverruled = await this.storeDiscount.getDiscountsOverruled(accountId, this.props.product.sku)
                    if (discountsOverruled) {
                        this.setState({
                            discountsOverruled: true,
                            discount: discountsOverruled.discountPerc,
                            minAmount: discountsOverruled.discountMinAmount
                        })
                    }
                }
            }
            
            componentDidUpdate = async(prevProps) => {
                const accountId = this.storeAuth.user.userId;
                let discount;

                if (prevProps.discount !== this.props.discount) {
                    const discountsOverruled = await this.storeDiscount.getDiscountsOverruled(accountId, this.props.product.sku)
                    //console.log('SbC fb', discountsOverruled)

                    if (discountsOverruled) {
                        this.setState({
                            discountsOverruled: true,
                            discount: discountsOverruled.discountPerc,
                            minAmount: discountsOverruled.discountMinAmount
                        }, () => this.setFeedback())
                    } else {
                        if ((this.storeAuth.user.isReseller && (this.props.amount < this.props.product.discountWholesaleMinAmount && this.props.product.discount > 0))) {
                            discount = this.props.product.discount;
                        } else {
                            discount = this.props.product.discountWholesale;
                        }
                        this.setState({
                            discountsOverruled: false,
                            discount: discount,
                            minAmount: this.props.product.discountWholesaleMinAmount
                        }, () => this.setFeedback())
                    }
                }

                if (prevProps.amount !== this.props.amount) {
                    this.setFeedback()
                }
            }

            setFeedback = () => {
                let feedback;
                let discount;
                let minAmount;
                const pack = this.props.product.id === 3 ? 'tubes' : 'packs';

                if (!this.state.discountsOverruled && this.storeAuth.user.isLoggedin) {
                    if ((this.storeAuth.user.isReseller && this.props.amount >= this.props.product.discountWholesaleMinAmount)) {
                        discount = this.state.discount;
                        feedback = 'A wholesale discount of ' + discount + '% has been applied.';
                    }

                    if ((this.storeAuth.user.isReseller && (this.props.amount < this.props.product.discountWholesaleMinAmount && this.props.product.discount > 0))) {
                        discount = this.state.discount;
                        feedback = 'A discount of ' + discount + '% has been applied.';
                    }

                    if ((this.storeAuth.user.isReseller && this.state.discount === 0)) {
                        minAmount = this.props.product.discountWholesaleMinAmount;
                        feedback = 'For the wholesale discount to be applied, a minimum purchase of ' + minAmount + ' ' + pack + ' is required';
                    }

                    if (!this.storeAuth.user.isReseller && this.props.amount >= this.props.product.discountMinAmount) {
                        feedback = 'A discount of ' + this.props.product.discount + '% has been applied.';
                    }
                }

                if (this.state.discountsOverruled) {
                    discount = this.state.discount;
                    if (this.storeAuth.user.isReseller) {
                        if (this.state.minAmount < this.props.product.discountWholesaleMinAmount) {
                            if (this.props.amount < this.state.minAmount) {
                                feedback = '(1) A discount is available when purchasing ' + this.state.minAmount + ' ' + pack + ' or more.';
                                if (this.state.discount < this.props.product.discountWholesale) {
                                    feedback = feedback + '<br/>A higher discount is available when purchasing ' + this.props.product.discountWholesaleMinAmount + ' ' + pack + ' or more.';
                                }
                            }
                            if (this.props.amount >= this.state.minAmount && this.props.amount < this.props.product.discountWholesaleMinAmount) {
                                feedback = '(3) A discount of ' + this.state.discount + '% is applied';
                                if (this.state.discount < this.props.product.discountWholesale) {
                                    feedback = feedback + '<br/>A higher discount is available when purchasing ' + this.props.product.discountWholesaleMinAmount + ' ' + pack + ' or more.';
                                } 
                            }
                            if (this.props.amount >= this.props.product.discountWholesaleMinAmount) {
                                if (this.state.discount < this.props.product.discountWholesale) {
                                    feedback = '(5) A wholesale discount of ' + this.props.product.discountWholesale + '% is applied';
                                } else {
                                    feedback = '(6) A discount of ' + this.state.discount + '% is applied';
                                }
                            }
                        }
                        if (this.state.minAmount >= this.props.product.discountWholesaleMinAmount) {
                            if (this.props.amount < this.props.product.discountWholesaleMinAmount) {
                                if (this.state.discount < this.props.product.discountWholesale) {
                                    feedback = '(7) A wholesale discount is available when purchasing ' + this.props.product.discountWholesaleMinAmount + ' ' + pack + ' or more.';
                                } else {
                                    feedback = '(8) A wholessale discount is available when purchasing ' + this.props.product.discountWholesaleMinAmount + ' ' + pack + ' or more.';
                                    feedback = feedback + '<br/>A higher discount is available when purchasing ' + this.state.minAmount + ' ' + pack + ' or more';
                                }
                            }
                            if (this.props.amount >= this.props.product.discountWholesaleMinAmount && this.props.amount < this.state.minAmount) {
                                feedback = '(9) A wholesale discount of ' + this.props.product.discountWholesale + '% is applied';
                                feedback = feedback + '<br/>A higher discount is available when purchasing ' + this.state.minAmount + ' ' + pack + ' or more';
                            }
                            if (this.props.amount >= this.state.minAmount) {
                                if (this.state.discount < this.props.product.discountWholesale) {
                                    feedback = '(10) A wholesale discount of ' + this.props.product.discountWholesale + '% is applied';
                                } else {
                                    feedback = '(11) A discount of ' + this.state.discount + '% is applied';
                                }
                            }
                        }
                    } else {
                        if (this.props.amount < this.state.minAmount) {
                            feedback = '(1a) A discount is available when purchasing ' + this.state.minAmount + ' ' + pack + ' or more.';
                        } else {
                            feedback = '(3a) A discount of ' + this.state.discount + '% is applied';
                        }
                    }
                }

                this.setState({
                    feedback: feedback
                })
            }

            render() {

                return (                  
                    <React.Fragment>
                        {/* {!this.state.discountsOverruled &&  
                            <React.Fragment>
                                {(this.storeAuth.user.isReseller && this.state.discount > 0) &&
                                    <div
                                        className = "cartitem cartitem__data-row cartitem__data-explanation"
                                    >
                                        A wholesale discount of {this.state.discount}% has been applied
                                    </div>
                                }
                                {(this.props.product && this.storeAuth.user.isReseller && this.props.amount < this.props.product.discountWholesaleMinAmount) &&
                                    <div
                                        className = "cartitem cartitem__data-row cartitem__data-explanation"
                                    >
                                        For the wholesale discount to be applied, a minimum purchase of {this.props.product.discountWholesaleMinAmount} {this.props.product.id === 3 ? 'tubes' : 'packs'} is required
                                    </div>
                                
                                }
                            </React.Fragment>
                        }

                        {this.state.discountsOverruled &&  
                            <React.Fragment>
                                {this.state.minAmount < this.props.product.discountWholesaleMinAmount &&
                                    <React.Fragment>
                                        {this.props.amount < this.state.minAmount &&
                                            <div
                                                className = "cartitem cartitem__data-row cartitem__data-explanation"
                                            >
                                                For the discount to be applied, a minimum purchase of {this.state.minAmount} {this.props.product.id === 3 ? 'tubes' : 'packs'} is required
                                            </div>
                                        }
                                         {this.props.amount >= this.state.minAmount &&
                                            <div
                                                className = "cartitem cartitem__data-row cartitem__data-explanation"
                                            >
                                                For the discount to be applied, a minimum purchase of {this.state.minAmount} {this.props.product.id === 3 ? 'tubes' : 'packs'} is required
                                            </div>
                                        }
                                    </React.Fragment>
                                }
                            
                                {this.props.amount >= this.state.minAmount &&
                                    <div
                                        className = "cartitem cartitem__data-row cartitem__data-explanation"
                                    >
                                        A discount of {this.state.discount}% has been applied
                                    </div>
                                }
                            </React.Fragment>
                        } */}

                        <div
                            className = "cartitem cartitem__data-row cartitem__data-explanation"
                            dangerouslySetInnerHTML={{ __html: i18n.t(this.state.feedback)}}
                        />
                        
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CartRowExplanation));
