import React, { Component } from 'react';
import { reaction, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import RowText from '../form-rows/row-text';
import FormFeedbackRequired from '../../elements/forms/form-feedback-required';
import TemplateForms from '../../templates/template-forms';

const FormVerification = inject("stores") (
    observer (
        class FormVerification extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    formControls: {          
                        code: {
                            type: "text",
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled',
                    fb: ''
                }
            }

            validateRow = (name, value, valid) => {  
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);
                this.storeUi.clearFormFeedback();
                this.hideFeedbackError();
                
                this.setState({
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    btn_class: formIsValid 
                        ?   '--active' 
                        :   '--disabled'
                });
            }

            showFeedbackError = () => {
                this.setState({
                    fb: "The code used is incorrect or the e-mail address is unknown in our administration.<br/>If you don't have an account yet, please register first."
                })
            }

            hideFeedbackError = () => {
                this.setState({
                    fb: ""
                })
            }

            verifyCode = async(e) => {
                if (this.state.formIsValid) {
                    const email = this.props.email;
                    let login = await this.storeAuth.verifyCode(email, this.state.formControls);

                    if (!('error' in login)) {
                        this.storeAuth.setVerified();
                        this.props.onNext();
                    } else {
                        this.showFeedbackError();
                    }
                }
            }

            render() {

                return (
                    <React.Fragment>
                        {this.props.view === 'init' &&
                            <React.Fragment>
                                <h2>
                                    Please pay attention
                                </h2>
                                <p>
                                    Due to a change of internet provider, unfortunately we can not use your current encrypted password anymore. For security reasons, we first need to verify that you are the owner of the e-mail address you have provided.
                                </p>
                                <p>
                                    Therefore we will send you first an e-mail with a verification code.<br/>After you received and filled in the verification code, you will able to set a new password, which you can choose yourself.
                                    <br/>
                                    From then on, you can login with your e-mail address and your newly set password.
                                </p>
                                <p>
                                    Please do <strong>not</strong> create a new account, as then you will not be able to access your previous order history and account details.
                                </p>
                                <p>
                                    <strong>Please note that this is a one time only procedure.</strong>
                                </p>
                                <p>
                                    Thank you for your understanding and sorry for the inconvenience.

                                </p>
                            </React.Fragment>
                        }

                        {this.props.view === 'pw' &&
                            <React.Fragment>
                                <h2>
                                    Verification of your e-mail address
                                </h2>
                                <p>
                                    Check your e-mail and fill in the verification code you have received.<br/>
                                    <strong>Please note that this process may take a minute or two. Also check your spam folder.</strong>
                                </p>
                            </React.Fragment>
                        }
                        
                        <TemplateForms
                            formClass = "login"
                            type = 'login'
                            msg_success = {i18n.t("form_feedback.success_login")}
                        >
                            

                            <form className="form">
                                <div className="form-fieldset">
                                    {Object.keys(this.state.formControls).map((key, i) => (
                                        <React.Fragment>
                                            {(this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email" || this.state.formControls[key].type === "password") &&
                                                <RowText 
                                                    type = {this.state.formControls[key].type}
                                                    name = {key}
                                                    value = {this.state.formControls[key].value}
                                                    validationRules = {this.state.formControls[key].validationRules}
                                                    onValidate = {this.validateRow}
                                                />
                                            }
                                        </React.Fragment>
                                    ))}
                                </div>
                            </form>
                                
                            <div className="form-row form-row--buttons">
                                <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.verifyCode(e)}>{i18n.t("button.verify")}</button>
                            </div>

                            {this.state.fb !== '' &&
                                <div className="form-row form-row--fb-error">
                                    <p 
                                        dangerouslySetInnerHTML={{ __html: i18n.t(this.state.fb)}}
                                    />
                                </div>
                            }
                        </TemplateForms>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(FormVerification);
