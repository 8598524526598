import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import Template from '../templates/template';
import FormLogin from '../components/forms/form-login';
import FormVerification from '../components/forms/form-verification';
import FormPasswordReset from '../components/forms/form-password-reset';

const Login = inject("stores") (
    observer (
        class Login extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storePageContent = this.props.stores.storePageContent;
                this.storeCart = this.props.stores.storeCart;

                this.state = {
                    redirectISBN: false,
                    redirectPayment: false,
                    id_quote: 0,
                    verified: true,
                    reset: false,
                    needsReset: false
                }
            }

            componentDidMount = async() => {
                this.storeUi.openDialogVerification();
                
                const page = "login";
                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);

                this.setState({
                    title: title,
                    blocks: blocks
                })
            }

            componentDidUpdate = () => {
               
            }

            checkVerification = async(formControls) => {
                let verified = false;
                let login;

                const data = {
                    email: formControls.email.value,
                    pw: formControls.password.value
                };

                verified = await this.storeAuth.checkVerification(data);

                if (verified) {
                    login = await this.storeAuth.checkLogin(formControls);

                    if ('error' in login) {
                        this.storeUi.setFormFeedback(login);
                    } else {
                        const cartId = await this.storeCart.getCartId(this.storeAuth.user.id);
                        const cartItems = await this.storeCart.getCartItems(cartId);
                        this.storeUi.setMsgVerification(0);

                        this.goto();
                    }
                } else {
                    const email = formControls.email.value;
                    this.setState({
                        verified: false,
                        email: email
                    })
                }
            }

            showVerification = (value) => {
                this.setState({
                    verified: !value
                })
            }

            onNextAction = () => {
                this.setState({
                    reset: true,
                    needsReset: true
                })
            }

            goto = () => {
                let url = "/shop";

                if (this.storeGeneral.origin !== '') {
                    url = '/' + this.storeGeneral.origin;
                }

                this.props.navigate(url);
            }

            gotoPage = (page) => {
                const url = "/" + page;
                this.props.navigate(url);
            }

            render() {

                return (                  
                    <Template
                        type = 'default'
                        title = {this.state.title && this.state.title}
                    >   
                        <React.Fragment>
                            <div
                                className = "wrapper-content"
                            >
                                {this.state.blocks && this.state.blocks.map((block, i) => (
                                    <div
                                        key = {i}
                                    >
                                        <p
                                             dangerouslySetInnerHTML={{ __html: block.textEN}}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div
                                className = "wrapper-content"
                            >
                                <FormLogin
                                    showVerification = {(value) => this.showVerification(value)}
                                    checkVerification = {this.checkVerification}  
                                />

                                {this.state.verified &&
                                    <div
                                        className = "wrapper-content --link"
                                        onClick = {() => this.gotoPage('password')}
                                    >
                                        Forgot your password?
                                    </div>
                                }

                                {!this.state.verified &&
                                    <FormVerification
                                        email = {this.state.email}
                                        onNext = {this.onNextAction}
                                        view = {'init'}
                                    />
                                }
                                
                                {this.storeAuth.getVerified() && this.state.needsReset &&
                                    <FormPasswordReset
                                        email = {this.state.email}
                                        onNext = {this.goto}
                                    />
                                }
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Login));
