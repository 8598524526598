import React, { Component }  from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";

import ButtonDialog from '../../elements/button-dialog';
import Checkbox from '../../elements/form-input-checkbox';

const DialogAddedToCart = inject("stores") (
    observer (
        class DialogAddeToCart extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storePageContent = this.props.stores.storePageContent;

                this.state = {
                    blocks: [],
                }
            }

            componentDidMount = async() => {
                document.addEventListener("keydown", this.escFunction, false);

                const page = "dialogaddedtocart";
                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);

                this.setState({
                    title: title,
                    blocks: blocks
                })
            }
            
            componentWillUnmount = () => {
                document.removeEventListener("keydown", this.escFunction, false);
            }

            escFunction = (event) => {
                if (event.keyCode === 27) {
                    this.props.onClose();
                }
            }

            handleClose = (e) => {
                this.props.onClose();
            }

            render() {
                if(!this.props.show) {
                    return null;
                }

                let dialogClass = "dialog--content";

                return (
                    <div className={"dialog " + dialogClass}>
                        <div className="dialog-header">
                            <h2>{this.state.title && this.state.title}</h2>
                        </div>
                        <div className="dialog-content">
                            {this.state.blocks && this.state.blocks.map((block, i) => (
                                <div
                                    key = {i}
                                >
                                    <p
                                        dangerouslySetInnerHTML={{ __html: block.textEN}}
                                    />
                                </div>
                            ))}
                        </div>
                        {this.props.buttons &&
                            <div className="dialog-footer">
                                {this.props.buttons.map((button, i) => (
                                    <ButtonDialog
                                        key = {i}
                                        caption = {button.text}
                                        onAction = {this.handleClose}
                                        btnClass = {button.btnClass}
                                    />
                                ))}
                            </div>
                        }
                    </div>
                    
                );
            }
        }
    )
)

export default withRouter(DialogAddedToCart);
