import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import Template from '../templates/template';
import FormEmail from '../components/forms/form-email';
import FormVerification from '../components/forms/form-verification';
import FormPasswordReset from '../components/forms/form-password-reset';

const Password = inject("stores") (
    observer (
        class Login extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storePageContent = this.props.stores.storePageContent;
                this.storeCart = this.props.stores.storeCart;

                this.state = {
                    id_quote: 0,
                    verified: true,
                    reset: false
                }
            }

            componentDidMount = async() => {
                const page = "password";
                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);

                this.setState({
                    title: title,
                    blocks: blocks
                })
            }

            componentDidUpdate = () => {
               
            }

            showVerification = (formControls) => {
                this.setState({
                    verified: false,
                    email: formControls.email.value
                }, () => this.checkVerification(formControls))
            }

            checkVerification = async(formControls) => {
                let login;

                const data = {
                    email: formControls.email.value,
                    pw: 'xx'
                };
                const verified = await this.storeAuth.checkVerification(data);
                const email = formControls.email.value;

                // this.setState({
                //     verified: false,
                //     email: email
                // })

                // if (verified) {
                //     // login = await this.storeAuth.checkLogin(formControls);

                //     // if ('error' in login) {
                //     //     this.storeUi.setFormFeedback(login);
                //     // } else {
                //     //     const cartId = await this.storeCart.getCartId(this.storeAuth.user.id);
                //     //     const cartItems = await this.storeCart.getCartItems(cartId);
                //     //     this.storeUi.setMsgVerification(0);

                //     //     this.goto();
                //     // }
                //     this.setState({
                //         reset: true
                //     })
                // } else {
                //     const email = formControls.email.value;
                //     this.setState({
                //         verified: false,
                //         email: email
                //     })
                // }
            }

            onNextAction = () => {
                this.storeAuth.setVerified();

                this.setState({
                    reset: true
                })
            }

            goto = () => {
                this.setState({
                    reset: false
                }, () => this.props.navigate("/"));

                // const url = "/";
                // this.props.navigate(url);
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        title = {this.state.title && this.state.title}
                    >   
                        <React.Fragment>
                            <div
                                className = "wrapper-content"
                            >
                                {this.state.blocks && this.state.blocks.map((block, i) => (
                                    <div
                                        key = {i}
                                    >
                                        <p
                                             dangerouslySetInnerHTML={{ __html: block.textEN}}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div
                                className = "wrapper-content"
                            >
                                <FormEmail
                                    showVerification = {(formControls) => this.showVerification(formControls)}
                                    //checkVerification = {(formControls) => this.checkVerification(formControls)}  
                                />

                                {!this.state.verified &&
                                    <FormVerification
                                        email = {this.state.email}
                                        onNext = {this.onNextAction}
                                        view = {'pw'}
                                    />
                                }
                                {this.state.reset == true &&
                                    <FormPasswordReset
                                        email = {this.state.email}
                                        onNext = {this.goto}
                                    />
                                }
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Password));
