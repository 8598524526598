import { observable, computed, action } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

class StoreVat {
    
    constructor() {

    }

    calcVat(total) {
        const percVat = 21;
        let vat = total - total / (1 + percVat/100);

        return vat;
    }
}

export default StoreVat;