import React from 'react';

const RadioIssuer = props => {

    let formControl = "form-control";
    let direction = "--" + props.direction;
    let urlAPI = process.env.REACT_APP_URL_API_PUBLIC;

    // if (props.touched && !props.valid) {
    //     formControl = 'form-control control-error';
    // }

    return (
        <div className={"form-group form-group--radio " + direction}>
            <ul className="payment-issuers__list">
                {props.options.map(option => (
                    <li className={"payment-issuers__listitem" + (parseInt(props.selected) === parseInt(option.code) ? " --selected" : '')} key={option.value}>
                        <label>
                            <input type="radio"
                                name = {props.name}
                                value = {option.value}
                                onChange = {props.onChange}
                                className = {formControl}
                                defaultChecked = {props.defaultValue === option.value}
                            />
                            <img src={urlAPI + "/public/images/payment/" + option.id + ".svg"} />
                            <span>{option.displayValue}</span>
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default RadioIssuer;