import * as mobx from 'mobx';
import { observable, computed, action, decorate } from "mobx";
import { runInAction } from "mobx";
import moment from "moment";

import * as account from '../communicator/account';
import * as tools from '../communicator/tools';
import * as login from '../communicator/login';
import * as sort from '../utilities/sort';
import * as ui from '../utilities/ui';

class StoreUser {
    user = { 
        
    }
    countryCode = 'NL'

    formFeedback = {}
    emailAvailable = 1

    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeGeneral = this.storeRoot.storeGeneral;
        this.storeAuth = this.storeRoot.storeAuth;
        this.storeCart = this.storeRoot.storeCart;
    }

    setLogoutState() {
 
    }

    checkEmailExists = async(email) => {
        let emailAvailable = 0;

        try {
            let returnData = await account.checkEmailExists(email);
            //console.log('SbC return', returnData)

            // if (returnData) {
                
            // } else {
            //     returnData = {
            //         data: [{
            //             'error': 'NOT OKx',
            //             'success': 'OKx'
            //         }]
            //     }
            // }

            runInAction(() => {
                if ('error' in returnData.data[0]) {                  
                    this.setEmailAvailable(returnData.data[0].error);
                    emailAvailable = 0;
                } else {
                    this.setEmailAvailable(returnData.data[0].success);
                    emailAvailable = 1;
                } 

                return emailAvailable;
            })
        } catch (error) {
            runInAction(() => {

            })
        } 

        return emailAvailable;
    }

    setEmailAvailable(value) {
        this.emailAvailable = value;
    }

    clearFormFeedback() {
        this.formFeedback = {};
    }

    async registerUser(formControls, country, form) {
        this.storeGeneral.startLoader();

        try {
            let returnData = await account.addUser(formControls, form);
            runInAction(() => {
                this.formFeedback = returnData.msg.fb;
            })
        } catch (error) {
            runInAction(() => {
                this.storeAuth.setLoginState(false);
                //this.setLoginState(false);
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    updateUser = async(formControls, country, form) => {
        this.storeGeneral.startLoader();

        const userId = this.storeAuth.user.id;
      
        try {
            let returnData = await account.updateUser(userId, formControls, country, form);
            runInAction(() => {
                this.formFeedback = returnData.msg;

                if (!('error' in returnData.data)) {
                       this.updateUserData(returnData.data[0]);
                       this.updateCartUserFromAccount(formControls);
                       this.form_feedback = returnData.msg.fb;
                } else {
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.storeAuth.setLoginState(false);
                //this.setLoginState(false);
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    updateUserData(data) {
        if ("countryCode" in data) {
            this.user.countryCode = data.countryCode;
            this.countryCode = data.countryCode;
        }
    }

    updateCartUserFromAccount = (formControls) => {
        this.storeCart.updateCartUserFromAccount(formControls);
    }

    async getUserData(id, email) {
        let data;
        let user;

        //this.storeGeneral.startLoader();
        try {
            let returnData = await account.getUser(id, email);
            runInAction(() => {
                data = returnData;
                if (!('error' in data)) {
                    this.user = data.data[0];
                    user = data.data[0];
                }
                //     this.setLoginState(true);
                //     this.storeAuth.setAuth(data);
                //     this.setUserData(data);
                //     this.form_feedback = returnData.msg.fb;
                // } else {
                //     this.storeAuth.setLoginState(false);
                //     this.setLoginState(false);
                // } 
                return user;
            })
        } catch (error) {
            runInAction(() => {
                // this.storeAuth.setLoginState(false);
                // this.setLoginState(false);
            })
        } finally {
            //this.storeGeneral.stopLoader();
        }

        return user;
    }
   
}

decorate(StoreUser, {
    emailAvailable: observable,
    formFeedback: observable,
    user: observable,
    countryCode: observable,
    checkEmailExists: action,
    updateCartUserFromAccount: action,
    registerUser: action
})

export default StoreUser;