import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import Template from '../../templates/template';
import ContentBanktansfer from './content-banktansfer';

const PaymentError = inject("stores") (
    observer (
        class PaymentError extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storePageContent = this.props.stores.storePageContent;
                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;

                this.state = {
                    btn_class: '--active',
                    paymentStatus: 'banktransfer'
                }
            }

            componentDidMount = async() => {
                const page = "payment-error";
                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);

                const orderId = this.storeOrders.getOrderId();
                const orderStatus = 'paymentError';
                const status = await this.storeOrders.setOrderStatus(orderId, orderStatus);
                
                this.setState({
                    title: title,
                    blocks: blocks,
                    orderStatus: orderStatus
                })
            }
            
            componentDidUpdate = () => {
               
            }

            componentWillUnmount = () => {
            //    this.storeOrders.removeOrderId();
            //    this.storeOrders.removeCartId();
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        title = {this.state.title}
                    >   
                        <React.Fragment>
                            <div
                                className = "wrapper-content"
                            >
                                {(this.state.orderStatus === undefined || this.state.orderStatus === '' || this.state.orderStatus === 'paymentError') &&
                                    <React.Fragment>
                                        {this.state.blocks && this.state.blocks.map((block, i) => (
                                            <div
                                                key = {i}
                                            >
                                                <p
                                                    dangerouslySetInnerHTML={{ __html: block.textEN}}
                                                />
                                                
                                            </div>
                                        ))}
                                        Go to <Link className="--link" to="/contact">contact form</Link>.
                                    </React.Fragment>
                                }
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(PaymentError));
