import React, { Component }  from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "../../withRouter";

import logo_img from '../../assets/images/branding/logo-abanda.png';
import Navigation from './navigation';
import CartSummary from '../shop/cart-summary';

const Header = inject("stores") (
    observer (
        class Header extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeCart = this.props.stores.storeCart;

                this.state = {
                    isLoggedin: false
                }
            }

            componentDidMount = async() => {
                // const accountId = this.storeAuth.user.userId;

                // const cartId = await this.storeCart.getCartId(accountId);
                
                // this.setState({
                //     cartId: cartId
                // })
            }

            componentDidUpdate = async(prevProps) => {

            }

            gotoPage = (section) => {
                const url = section;
                this.props.navigate(url);
            }

            render() {
                return (
                    <header className="wrapper-header">
                        <div className="wrapper-header__columns">
                            <div 
                                className = "header__content header__content--branding"
                            >
                                <img 
                                    src={logo_img} 
                                    className="header__logo --link" 
                                    alt="logo image abanda.nl" 
                                    onClick = {() => {this.gotoPage('/')}}
                                />
                                <CartSummary 
                                    cartId = {this.storeCart.cartId}
                                    nrItems = {this.storeCart.nrItems}
                                    amount = {this.storeCart.amount}
                                    isReseller = {this.storeAuth.user.isReseller}
                                    total = {this.storeCart.total}
                                />
                            </div>
                        </div>
                        <div className="header__content">
                            <Navigation 
                                isLoggedin = {this.storeAuth.isLoggedin}    
                            />
                        </div>
                    </header>
                )
            }
        }
    )
)

export default withRouter(Header);

