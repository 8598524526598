import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link, Navigate} from 'react-router-dom';

import Template from '../../templates/template';
import FormPayment from '../../components/forms/form-payment';

const Payment = inject("stores") (
    observer (
        class Payment extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storePageContent = this.props.stores.storePageContent;
                this.storeOrders = this.props.stores.storeOrders;

                this.refActions = React.createRef();

                this.state = {
                    paymentMethod: '',
                    paymentIssuer: '',
                    btn_class: '--disabled',
                    redirect: false,
                }
            }

            componentDidMount = async() => {
                const page = "payment";
                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);

                this.storeOrders.getPaymentMethods();

                this.setState({
                    title: title,
                    blocks: blocks
                })
            }
            
            componentDidUpdate = () => {
               
            }

            gotoPage = (page) => {
                const url = "/" + page;
                this.props.navigate(url);
            }

            submitOrder = async() => {
                const orderId = await this.storeOrders.submitOrder();
              
                const status = this.state.paymentMethod;
                const orderStatus = await this.storeOrders.setOrderStatus(orderId, status);

                const method = this.state.paymentMethod;
                let issuer = '';
                if (method === 'IDEAL') {
                    issuer = this.storeOrders.paymentIssuers[this.state.paymentIssuer].code;
                } 

                const data = {
                    method: method,
                    issuer: issuer
                };

                const paymentLink = await this.storeOrders.getPaymentLink(orderId, data);

                if (paymentLink !== undefined) {
                    //console.log('SbC link', paymentLink)
                    window.location.replace(paymentLink);
                } else {
                    this.gotoPage('payment-error')
                }
            }

            completeOrder = async() => {
                const orderId = await this.storeOrders.submitOrder();
                const orderStatus = await this.storeOrders.setOrderStatus(orderId, 'bankTransfer');
               
                const url = 'payment-result?orderid=' + orderId + '&status=success';
                this.gotoPage(url);
            }

            setPaymentMethod = (value) => {
                switch(value) {
                    case 'IDEAL':
                        this.setState({
                            paymentMethod: value,
                            paymentIssuer: '',
                            btn_class: '--disabled'
                        })
                        break;
                    default:
                        this.setState({
                            paymentMethod: value,
                            paymentIssuer: '',
                            btn_class: '--active'
                        }, () => this.scrollToBtn())
                } 
            }

            setPaymentIssuer = (value) => {
                const name = 'payment_issuer';
                const displayValue = 'Bank';

                this.storeOrders.setPaymentData(name, value, displayValue);

                this.setState({
                    paymentIssuer: value,
                    btn_class: '--active'
                }, () => this.scrollToBtn())
            }

            scrollToBtn = () => {
                this.refActions.current.scrollIntoView({
                    behavior: 'smooth',
                    block: "end"
                });
            }

            startPayment = async(payment_id, issuer_code) => {

            }

            render() {
                if (this.state.redirect) {
                    return <Navigate 
                        to={"/paymentmsp"} 
                    />;
                }

                const payment_id = 'IDEAL';

                const formValid = (payment_id === 'IDEAL') ? false : true;
                const btn_class = (payment_id === 'IDEAL') ? '--disabled' : '--active';

                return (                  
                    <Template
                        type = 'default'
                        title = {this.state.title && this.state.title}
                    >   
                        <React.Fragment>
                            <div
                                className = "wrapper-content"
                            >
                                {this.state.blocks && this.state.blocks.map((block, i) => (
                                    <div
                                        key = {i}
                                    >
                                        <p
                                             dangerouslySetInnerHTML={{ __html: block.textEN}}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div
                                className = "wrapper-content"
                            >
                                <FormPayment
                                    countryCode = {this.storeOrders.countryCode}
                                    paymentMethods = {this.storeOrders.paymentMethods}
                                    setPaymentMethod = {this.setPaymentMethod}
                                    setPaymentIssuer = {this.setPaymentIssuer}
                                />
                            </div>

                            <div
                                className = "wrapper-content"
                            >
                                <div className="wrapper-content__actions">
                                    <div className="form-row form-row--buttons">
                                        <button className={"btn btn--secundary " + this.state.btn_class} onClick={(e) => this.gotoPage('checkout')}>{i18n.t("button.goback_checkout")}</button>
                                        {this.state.paymentMethod === 'BANKTRANS' &&
                                            <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.completeOrder()}>{i18n.t("button.complete_payment")}</button>
                                        }
                                        {this.state.paymentMethod !== 'BANKTRANS' &&
                                            <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.submitOrder()}>{i18n.t("button.goto_payment_provider")}</button>
                                        }
                                    </div>
                                </div>
                                
                                <div ref={this.refActions}>
                                    {(this.state.paymentMethod !== '' && this.state.paymentIssuer !== '' && this.state.paymentMethod !== 'BANKTRANS') &&
                                        <div className="wrapper-content__feedback">
                                            <p>Je komt op de volgende pagina in de betaalomgeving van je bank of onze betaalprovider MultiSafepay. Zij regelen alle betalingen voor de webshop van Succesboeken.nl en is een veilige betaalomgeving.</p>
                                            <p>Je komt na betaling vanzelf op onze website terug.</p>
                                        </div>
                                    }

                                    {(this.state.paymentMethod !== '' && this.state.paymentMethod === 'BANKTRANS') &&
                                        <div className="wrapper-content__feedback">
                                            <p>Je vindt op de volgende pagina de overschrijvingsgegevens.<br/>Deze zijn ook opgenomen in de bevestigingsmail die je dan toegezonden wordt.</p>
                                            <p>Na ontvangst van de betaling wordt de bestelling onmiddellijk toegestuurd.</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Payment));
