import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "../withRouter";
//import { withRouter } from 'react-router-dom';
import {
    isBrowser,
    isMobile,
    isIOS
} from "react-device-detect";
import * as rdd from "react-device-detect";

import * as ui from '../utilities/ui';

const Template = inject("stores") (
    observer (
        class Template extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
            }

            componentDidMount() {
                ui.smoothScrollTo(0, 0, 100); 
            }

            componentDidUpdate = async() => {
                
            }

            render() {
                return (
                    <React.Fragment>
                        <div
                            className = "wrapper-page"
                        >
                            {this.props.title !== '' && this.props.title !== undefined &&
                                <React.Fragment>
                                    <div
                                        className = "wrapper-content"
                                    >
                                        <h1
                                            dangerouslySetInnerHTML={{ __html: this.props.title}}
                                        />
                                        <h2
                                            className = "wrapper-content__subtitle"
                                        >
                                            {this.props.subtitle}
                                        </h2>
                                    </div>
                                </React.Fragment>
                            }

                            {this.props.children}
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(Template);
