import React, { Component } from 'react';
import { runInAction, reaction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import validate from '../../utilities/validation-rules';
import * as ui from '../../utilities/ui';

import TemplateForms from '../../templates/template-forms';
import RowText from '../form-rows/row-text';
import Select from '../../elements/form-input-select';

const FormRegister = inject("stores") (
    observer (
        class FormRegister extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;

                this.fieldTitles = [
                    'profile',
                    'password'
                ];

                this.state = {
                    form: 'register',
                    formControls: { 
                        firstname: {
                            type: "text",
                            group: 1,
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        middlename: {
                            type: "text",
                            group: 1,
                            value: '',
                            validationRules: {
                            },
                            valid: true
                        },  
                        lastname: {
                            type: "text",
                            group: 1,
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },         
                        email: {
                            type: "email",
                            group: 1,
                            value: '',
                            validationRules: {
                                isRequired: true,
                                isEmail: true
                            },
                            validationMsg: i18n.t("form_errors.email"),
                            valid: false
                        },
                        email2: {
                            type: "email",
                            group: 1,
                            value: '',
                            validationRules: {
                                isRequired: true,
                                isEmail: true
                            },
                            validationMsg: i18n.t("form_errors.email"),
                            valid: false,
                            disable: ["paste"]
                        },
                        phonenr: {
                            type: "text",
                            group: 1,
                            value: '',
                            validationRules: {  
                                isRequired: true,
                                isPhoneNumber: true
                            },
                            valid: false
                        },
                        country_select: {
                            type: "select",
                            group: 1,
                            label: i18n.t("forms.label.country"),
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceCountryCode === '' || this.props.user.invoiceCountryCode === null
                                    ?   'NL'
                                    :   this.props.user.invoiceCountryCode
                                :   'NL',
                            placeholder: i18n.t("forms.placeholder.country"),
                            options: this.storeGeneral.listCountries,
                            validationRules: {},
                            valid: true
                        },
                        password: {
                            type: "password",
                            group: 2,
                            value: '',
                            validationRules: {
                                isRequired: true,
                                minLength: 5
                            },
                            validationMsg: 'ruleMsg',
                            valid: false
                        },
                        password2: {
                            type: "password",
                            group: 2,
                            value: '',
                            validationRules: {
                                isRequired: true,
                                minLength: 5
                            },
                            validationMsg: 'ruleMsg',
                            disable: ["paste"],
                            valid: false
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled',
                    error: false,
                    error_email: false,
                    error_email2: false,
                    error_email_match: false,
                    error_pw_match: false,
                    classFeedback: ''
                }
            }

            componentDidMount = () => {
                //this.storeUser.clearFormFeedback();
            }

            componentWillUnmount = () => {
                //this.storeUser.clearFormFeedback();
            }

            validateRow = (name, value, valid, touched) => {  
                this.storeUi.clearFormFeedback();

                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid, touched);

                this.setState({
                    error: null,
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateForm = async() => {
                let matchingEmail;
                let error_email_match;

                let matchingPw;
                let error_pw_match;

                let emailAvailable = true;
                let emailAvailable2 = true;

                if (this.state.formControls.email.value !== '' && this.state.formControls.email2.value !== '') {
                    matchingEmail = ui.checkMatchingValues(this.state.formControls.email.value, this.state.formControls.email2.value);
                    error_email_match = !matchingEmail;
                }

                if (this.state.formControls.password.value !== '' && this.state.formControls.password2.value !== '') {
                    matchingPw = ui.checkMatchingValues(this.state.formControls.password.value, this.state.formControls.password2.value);
                    error_pw_match = !matchingPw;
                }

                let emailValue = this.state.formControls.email.value;
                if (emailValue !== '') {
                    emailAvailable = await this.checkEmail(emailValue);                    
                }

                let emailValue2 = this.state.formControls.email2.value;
                if (emailValue2 !== '') {
                    emailAvailable2 = await this.checkEmail(emailValue2);                    
                }

                const formIsValid = matchingEmail && matchingPw && emailAvailable && emailAvailable2 && this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    error_firstname: this.state.formControls.firstname.value.length >= 2 ? false : this.state.error_firstname,
                    error_email: !emailAvailable,
                    error_email2: !emailAvailable2,
                    error_email_match: error_email_match,
                    error_pw_match: error_pw_match,
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                });
            }

            checkEmail = async(value) => {
                let emailAvailable = await this.storeUser.checkEmailExists(value);

                return emailAvailable;    
            }

            handleBlur = async() => {
                let emailAvailable = true;

                let emailValue = this.state.formControls.email.value;
                if (emailValue !== '') {
                    emailAvailable = await this.checkEmail(emailValue);                    
                }

                this.setState({
                    error_email: !emailAvailable,
                    error_firstname: (this.state.formControls.firstname.value !== '' && this.state.formControls.firstname.value.length === 1) ? true : false
                })
            }

            handleDropdown = (action, countryCode) => {
                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls['country_select']
                };
                updatedFormElement.value = countryCode;
                updatedControls['country_select'] = updatedFormElement;

                this.setState({
                    notificationCountry: action === 'auto' 
                    ?   (countryCode !== this.state.formControls.country_select.value && (countryCode === 'NL' || countryCode === 'BE')) ? true : false
                    :   false,
                    formControls: updatedControls
                }, () => this.validateForm()); 
            }

            registerUser = async(e) => {
                e.preventDefault();
                if (this.state.formIsValid && this.state.btn_class !== '--disabled') {
                    this.setState({
                        btn_class: '--disabled'
                    })
                    let registered = await this.storeUser.registerUser(this.state.formControls, 'NL', this.state.form);
                    if ('success' in this.storeUser.formFeedback) {
                        const login = await this.storeAuth.checkLogin(this.state.formControls);
                        this.props.onNext();
                    }
                }
            }

            handleClose = (e) => {
                this.setState({
                    classFeedback: '--inline'
                })
            }

            render() {
                return (
                    <TemplateForms
                        formClass = "register"
                        type = 'register'
                        msg_success = {i18n.t("form_feedback.success_register")}
                    >
                        <form className="form form--register">
                            {[...Array(2)].map((x, idx) =>
                                <div className="form-fieldset">
                                    <div
                                        className = "form__fieldset-title"
                                        dangerouslySetInnerHTML={{ __html: i18n.t("form_fieldset-title." + this.fieldTitles[idx])}}
                                    />
                                    {Object.keys(this.state.formControls).map((key, i) => (
                                        (idx + 1) === this.state.formControls[key].group &&
                                            <React.Fragment>
                                                {(this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email" || this.state.formControls[key].type === "password") &&
                                                    <RowText 
                                                        type = {this.state.formControls[key].type}
                                                        name = {key}
                                                        value = {this.state.formControls[key].value}
                                                        validationRules = {this.state.formControls[key].validationRules}
                                                        validationMsg = {this.state.formControls[key].validationMsg}
                                                        onValidate = {this.validateRow}
                                                        onBlur = {this.handleBlur}
                                                        disable = {this.state.formControls[key].disable}
                                                    />
                                                }
                                               
                                                {key === "firstname" && this.state.error_firstname &&
                                                    <span className="form-field--suggestion">{i18n.t("form_suggestions.firstname")}</span>
                                                }

                                                {((key === "email" && this.state.error_email === true) || (key === "email2" && this.state.error_email2)) &&
                                                    <span className="form-field--error --email">{i18n.t("form_errors.email_in_use_extended")}</span>
                                                }

                                                {key === "email2" && this.state.error_email_match &&
                                                    <span className="form-field--error --email">{i18n.t("form_errors.email_not_matching")}</span>
                                                }

                                                {key === "password2" && this.state.error_pw_match &&
                                                    <span className="form-field--error --pw">{i18n.t("form_errors.pw_not_matching")}</span>
                                                }

                                                {key === "country_select" &&
                                                    <div className="form-row form-row--select">
                                                        <div className="form-column">
                                                            {this.state.formControls['country_select'].label}
                                                        </div>
                                                        <div className="form-column">
                                                            <Select 
                                                                name="country_select"
                                                                value = {this.state.formControls.country_select.value}
                                                                options = {this.state.formControls.country_select.options ? this.state.formControls.country_select.options : []}
                                                                touched = {this.state.formControls.country_select.touched}
                                                                onChange = {(e) => this.handleDropdown('dd', e.target.value)}
                                                                valueSelected = {this.state.formControls.country_select.value}
                                                                defaultValue = {this.state.formControls.country_select.value}
                                                                langage = {this.storeGeneral.language}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </React.Fragment>
                                    ))}

                                    {this.state.error &&
                                        <div className="form-row form-row--error">
                                            <p>{i18n.t("errors.error_general")}</p>
                                        </div>
                                    }
                                </div>
                            )}
                        </form>

                        <div className="form-row form-row--buttons">
                            <button 
                                className={"btn btn--primary " + this.state.btn_class} 
                                onClick={(e) => this.registerUser(e)}
                            >
                                {i18n.t("button.register")}
                            </button>
                        </div>
                                    
                        {/* {Object.keys(this.storeUser.form_feedback).length > 0 &&
                            <React.Fragment>
                                {'error' in this.storeUser.form_feedback &&     
                                    <div className={"form__feedback form__feedback--dialog " + this.state.classFeedback}>
                                    <div className="btn--close" onClick={(e) => this.handleClose(e)}><div className="btn--icon --link icon--close">Sluit</div></div>
                                        <span>Er is helaas een fout opgetreden</span>
                                        <span>Probeer het later nog een keer.</span>
                                    </div>                  
                                }
                            </React.Fragment>
                        } */}
                    </TemplateForms>
                )
            }
        }
    )
)

export default withTranslation()(FormRegister);
