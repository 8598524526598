import * as apifetch from './fetch.js';

export async function getProducts() {
    const urlEndpoint = '/products/list';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getProduct(id) {
    const urlEndpoint = '/products/' + id;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getProductInfo() {
    const urlEndpoint = '/products/info';

    return apifetch.authFetch(urlEndpoint, 'GET');
}