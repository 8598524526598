import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

const ProductUse = inject("stores") (
    observer (
        class ProductUse extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API_PUBLIC;
                
                this.storeProducts = this.props.stores.storeProducts;

                this.state = {
                    products: []
                }
            }

            componentDidMount = async() => {
                
            }

            componentDidUpdate = () => {

            }

            downloadPdf = (lang) => {
                const file = this.urlAPI + "/public/pdf/leafletProgesterine-" + lang + ".pdf";
                window.open(file);
            }
        
            render() {
                return (
                    <div
                        key = {this.props.key}
                        className = "wrapper-product"
                    >
                        <ul
                            className = "links--download"
                        >
                            <li
                                className = "link--download --link"
                                onClick = {() => this.downloadPdf('SE')}
                            >
                                <img 
                                    src={this.urlAPI + "/public/images/icons/ic-pdf-black.png"}
                                    className = "ic--download"
                                />
                                <span>Bruksanvisning (Svensk pdf)</span>
                            </li>
                            <li
                                className = "link--download --link"
                                onClick = {() => this.downloadPdf('EN')}
                            >
                                <img 
                                    src={this.urlAPI + "/public/images/icons/ic-pdf-black.png"}
                                    className = "ic--download"
                                />
                                <span>Directions for use (English pdf)</span>
                            </li>
                        </ul>  
                    </div>
                )
            }
        }
    )
)

export default ProductUse;
