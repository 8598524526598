import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

//import countries from "i18n-iso-countries";
import * as sort from '../utilities/sort';

import * as settings from '../communicator/settings';
import * as tools from '../communicator/tools';
import { act } from "react-dom/test-utils";

class StoreUi {
    app_interface = { 
        isDialogContentOpen: false,
        isDialogVerificationOpen: false
    }
    isDialogContentOpen = false
    isDialogVerificationOpen = false
    isDialogAddedToCartOpen = false
    activeTab = 'profile'
    formFeedback = {}
    settings_list = []

    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeGeneral = this.storeRoot.storeGeneral;
        this.storeAuth = this.storeRoot.storeAuth;
        //this.getLanguages();
        this.getSettings();
    }

    async getSettings() {
        let settingsList;
        try {
            const returnData = await settings.getSettings();
            runInAction(() => {
                settingsList = returnData.data;
                settingsList.map(setting => {
                    this.settings_list[setting.SettingName] = setting.SettingValue;
                })
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getLanguages() {
        try {
            const returnData = await settings.getLanguages();
            runInAction(() => {
                this.list_languages = returnData.data;
                this.languages = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    openDialogContent = () => {
        this.app_interface.isDialogContentOpen = true;
        this.isDialogContentOpen = true;
    }

    openDialogVerification = () => {
        this.app_interface.isDialogVerificationOpen = true;
        this.isDialogVerificationOpen = true;
    }

    openDialogAddedToCart = () => {
        this.app_interface.isDialogAddedToCartOpen = true;
        this.isDialogAddedToCartOpen = true;
    }

    closeDialogContent = () => {
        this.app_interface.isDialogContentOpen = false;
        this.isDialogContentOpen = false;
    }

    closeDialogVerification = () => {
        this.app_interface.isDialogVerificationOpen = false;
        this.isDialogVerificationOpen = false;
    }

    closeDialogAddedToCart = () => {
        this.app_interface.isDialogAddedToCartOpen = false;
        this.isDialogAddedToCartOpen = false;
    }

    setMsgVerification = (value) => {
        localStorage.setItem('ABANDA_DIALOGVERIFICATION', value)
    }

    checkMsgVerification = () => {
        let showDialog;

        if (localStorage.getItem('ABANDA_DIALOGVERIFICATION')) {
            if (parseInt(localStorage.getItem('ABANDA_DIALOGVERIFICATION'))) {
                showDialog = 1;
            } else {
                showDialog = 0;
            }
        } else {
            showDialog = 1;
        }

        return showDialog;
    }

    setActiveTab(value) {
        this.activeTab = value;
    }

    clearFormFeedback() {
        this.formFeedback = {};
    }

    setFormFeedback(fb) {
        this.formFeedback = fb;
    }

    setContentComponent(comp) {
        this.content_component = comp;
        return true;
    }
}

decorate(StoreUi, {
    app_interface: observable,
    activeTab: observable,
    formFeedback: observable,
    isDialogContentOpen: observable,
    isDialogVerificationOpen: observable,
    isDialogAddedToCartOpen: observable,
    openDialogContent: action,
    openDialogVerification: action,
    openDialogAddedToCart: action,
    closeDialogContent: action,
    closeDialogVerification: action,
    closeDialogAddedToCart: action,
    setMsgVerification: action,
    clearFormFeedback: action,
    setFormFeedback: action
})

export default StoreUi;