import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import validate from '../../utilities/validation-rules';
import TextInput from '../../elements/form-input-text';

const RowText = inject("stores") (
    observer (
        class RowText extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    formControls: { 
                        textinput: {
                            name: this.props.name,
                            value: this.props.value,
                            touched: this.props.touched === true 
                                ?   true
                                :   false,
                            validationRules: this.props.validationRules,
                            validationMsg: this.props.validationMsg 
                                ?   this.props.validationMsg 
                                :   this.props.type == 'email' ? i18n.t("form_errors.email") : i18n.t("form_errors.general"),
                            valid: true,
                            required: this.props.required,
                            secret: this.props.type === 'password' ? true : false
                        }
                    },
                    classError: ''
                }
            }

            componentDidMount = () => {
                if (this.props.validateRowOnMount) {
                    const isValid = validate(this.state.formControls.textinput.value, this.state.formControls.textinput.validationRules);
                    const classError = (!isValid
                        ?   " --error" 
                        :   ' '
                    );
                    this.setState({
                        classError: classError
                    })
                }
            }

            componentDidUpdate = (prevProps) => {
                const name = 'textinput';

                //if (this.props.syncedEmail && (prevProps.value !== this.props.value)) {
                if ((prevProps.value !== this.props.value)) {
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls[name]
                    };

                    updatedFormElement.value = this.props.value;
                    updatedFormElement.valid = validate(this.props.value, updatedFormElement.validationRules);

                    updatedControls[name] = updatedFormElement;

                    this.setState({
                        formControls: updatedControls,
                        classError: ''
                    });
                }

                if ((prevProps.reset !== this.props.reset && this.props.reset == true)) {
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls[name]
                    };

                    updatedFormElement.touched = false;
                    updatedFormElement.value = '';
                    updatedFormElement.valid = true;

                    updatedControls[name] = updatedFormElement;

                    this.setState({
                        formControls: updatedControls,
                        classError: ''
                    });
                }
            }

            handleChange = (event, action) => {     
                const name = 'textinput';
                let value = event.target.value;
                let validated;

                //SbC no spaces allowed in email
                if (this.props.type === 'email') {
                    value = value.replace(/\s+/g, '');
                }

                //SbC replace apostroph
                value = value.replace(/'/g, '`');

                //SbC no trailing spaces in zipcode after 5 characters
                if (this.props.name === 'zipcode' && value.length > 6) {
                    value = value.trim();
                }
          
                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };

                //updatedFormElement.touched = true;
                updatedFormElement.value = value;
                
                if (updatedFormElement.validationMsg === "ruleMsg") {
                    validated = validate(value, updatedFormElement.validationRules, "msg");
                    updatedFormElement.valid = validated.value;
                    updatedFormElement.validationMsg = validated.msg;
                } else {
                    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
                }

                if (this.props.type === 'email' && !updatedFormElement.touched) {
                    updatedFormElement.valid = true;
                }

                updatedControls[name] = updatedFormElement;

                this.setState({
                    formControls: updatedControls,
                    classError: validate(value, updatedFormElement.validationRules) 
                        ?   " " 
                        :   " --error" 
                }, () => this.props.onValidate(this.props.name, value, updatedFormElement.valid, updatedFormElement.touched)); 
            }

            handleBlur = () => {
                const name = 'textinput';
          
                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };

                if (this.state.formControls[name].value === "") {
                    if (updatedFormElement.touched !== true) {
                        updatedFormElement.touched = true;
                    }
                } else {
                    updatedFormElement.touched = true;
                }  

                //SbC remove trailing spaces
                let value = updatedFormElement.value;
                if (value !== null) {
                    updatedFormElement.value = value.trim();
                }

                if (this.props.type === 'email') {
                    updatedFormElement.valid = validate(this.state.formControls[name].value, updatedFormElement.validationRules);
                }

                updatedControls[name] = updatedFormElement;

                this.setState({
                    formControls: updatedControls,
                }, () => this.props.onBlur && this.props.onBlur(this.props.name, this.state.formControls[name].value, updatedFormElement.valid, updatedFormElement.touched)); 
            }

            showSecret = () => {
                const name = 'textinput';
          
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.secret = !this.state.formControls[name].secret;
                updatedControls[name] = updatedFormElement;
               
                this.setState({
                    formControls: updatedControls
                }); 
            }

            render() {
                const objRules = this.props.validationRules;
                const required = (Object.keys(objRules).length > 0 && 'isRequired' in objRules && objRules.isRequired)
                    ?   ' --required'
                    :   ''

                return (
                    <div 
                        className={"form-row form-row--input" + (
                            (Object.keys(objRules).length > 0 && 'isRequired' in objRules && 
                             this.state.formControls.textinput.touched === true && 
                             this.state.formControls.textinput.value === '') ||
                             (!this.state.formControls.textinput.valid && (Object.keys(objRules).length > 0) && this.state.formControls.textinput.value !== '')
                                ?   " --error" 
                                :   ' '
                            ) + this.state.classError} 
                    >
                        <div className="form-column">
                            <label className={"form-label" + required}>
                                {this.props.label ? this.props.label : i18n.t("forms.label." + this.props.name)}
                            </label>
                        </div>
                        <div className="form-column">
                            <TextInput 
                                type = {this.props.type} 
                                name = {this.props.name} 
                                placeholder = {this.props.placeholder ? this.props.placeholder : i18n.t("forms.placeholder." + this.props.name)}
                                value = {this.state.formControls.textinput.value}
                                touched = {this.state.formControls.textinput.touched}
                                valid = {this.state.formControls.textinput.valid}
                                onChange = {this.handleChange}
                                onBlur = {this.handleBlur}
                                msg = {this.state.formControls.textinput.validationMsg}
                                secret = {this.state.formControls.textinput.secret}
                                showSecret = {this.showSecret}
                                disable = {this.props.disable}
                                readonly = {this.props.readonly}
                                required = {required}
                            />
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(RowText);
