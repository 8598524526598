import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { Link } from 'react-router-dom';

import FormFeedbackRequired from '../elements/forms/form-feedback-required';

const TemplateForms = inject("stores") (
    observer (
        class TemplateForms extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;

                this.state = {
                    classFeedback: ''
                }
            }

            componentDidMount = () => {
                //this.storeUi.clearFormFeedback();
            }

            handleClose = (e) => {
                this.setState({
                    classFeedback: '--inline'
                })
            }
  
            render() {
                return (
                    <React.Fragment>
                        <div className={"form-wrapper form-wrapper--" + this.props.formClass}>
                            <FormFeedbackRequired />
                        
                            {this.props.children}

                            {(this.props.type !== 'vraag/opmerking' && this.storeUi.form_feedback) &&
                                Object.keys(this.storeUi.form_feedback).length > 0 &&                    
                                    <div className={"form__feedback form__feedback--dialog " + this.state.classFeedback}>
                                        <div className="btn--close" onClick={(e) => this.handleClose(e)}><div className="btn--icon --link icon--close">Sluit</div></div>
                                        {'success' in this.storeUi.form_feedback &&
                                            <React.Fragment>
                                                <span className="form__feedback--success">{i18n.t("form_feedback.success", {type: this.props.type})}</span>
                                                {this.props.msg_success && 
                                                    <span>{this.storeUi.form_feedback.success}</span>
                                                }
                                            </React.Fragment>
                                        }
                                        {'error' in this.storeUi.form_feedback && (
                                            'error_code' in this.storeUi.form_feedback 
                                            ?   <span className="form__feedback--error" dangerouslySetInnerHTML={{ __html: this.storeUi.form_feedback.error}} />
                                            :   <span className="form__feedback--error">{i18n.t("form_feedback.error", {type: this.props.type})}</span>
                                        )}

                                        {('error_code' in this.storeUi.form_feedback && this.storeUi.form_feedback.error_code === 101) &&
                                            <span className="form__feedback--error">Of maak een <Link to="/register">nieuw account</Link> aan met dit e-mailadres.</span>
                                        }
                                    </div>
                            }
                            
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default TemplateForms;
