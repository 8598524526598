import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import Version from './version';

const Footer = inject("stores") (
    observer (
        class Footer extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeAuth = this.props.stores.storeAuth;

                this.state = {}
            }

            componentDidMount = () => {
                const d = new Date();
                const year = d.getFullYear();
                
                this.setState({
                    year: year
                })
            }

            componentDidUpdate = (prevProps) => {
            
            }
        
            render() {
                return (
                    <footer className="wrapper-footer">
                        <div className="wrapper-footer__columns wrapper-footer__columns--sitemap">
                            <div className="wrapper-footer__column">
                                <h2>Pages</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/shop">Shop</Link></li>
                                    <li><Link to="/faq">Faq</Link></li>
                                    {!this.storeAuth.isLoggedin &&
                                        <li><Link to="/login">Login</Link></li>
                                    }
                                    <li><Link to="/register">Register</Link></li>
                                    <li><Link to="/wholesale">Register wholesale</Link></li>
                                    {this.storeAuth.isLoggedin &&
                                        <li><Link to="/account">My account</Link></li>
                                    }
                                </ul>
                            </div>
                            <div className="wrapper-footer__column">
                                <h2>About</h2>
                                <ul>
                                    <li><Link to="/about">About Abanda</Link></li>
                                    <li><Link to="/wholesale">Become a wholesale reseller</Link></li>
                                    <li><Link to="/privacy-policy">Privacy policy</Link></li>
                                    <li><Link to="/contact">Contact</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="wrapper-footer__columns wrapper-footer__columns--colofon">
                            <div className="wrapper-footer__column">
                                <strong>Abanda.nl</strong><br/>
                                Chamber of commerce: 30147850<br/>
                                VAT nr.: 8067.90.246.B01<br/>
                                <br/>
                                Abanda.nl is a tradename of<br/>Belfra Publishers for Success bv
                            </div>
                            <div className="wrapper-footer__column">
                                <strong>Belfra Publishers for Success bv</strong><br/>
                                Dolderseweg 49<br/>
                                3734 BB Den Dolder<br/>
                                The Netherlands<br/>
                                info@abanda.nl<br/>
                                +31 30 2285600<br/>
                            </div>
                        </div>

                        <div className="wrapper-footer__columns wrapper-footer__columns--colofon">
                            <div className="wrapper-footer__column">
                                <Version />
                            </div>
                        </div>

                        
                    </footer>
                )
            }
        }
    )
)

export default Footer;
