import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "../../withRouter";

const ProductPriceButton = inject("stores") (
    observer (
        class ProductPriceButton extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_PUBLIC;
                
                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeDiscount = this.props.stores.storeDiscount;
                this.storeCart = this.props.stores.storeCart;

                this.state = {
                    inCart: false
                }
            }

            componentDidMount = async() => {
                let priceDiscounted;

                if (parseInt(this.storeAuth.user.isReseller) === 1) {
                    priceDiscounted = this.storeDiscount.showPriceWholesale(this.props.product);
                } else {
                    priceDiscounted = this.storeDiscount.showPriceDiscount(this.props.product);
                }

                this.setState({
                    priceDiscounted: priceDiscounted
                },(() => this.checkInCart()))
            }

            componentDidUpdate = () => {

            }

            downloadPdf = (lang) => {
                const file = this.urlPublic + "/public/pdf/leafletProgesterine-" + lang + ".pdf";
                window.open(file);
            }

            gotoProduct = (sku) => {
                const url = "/product/" + sku;
                this.props.navigate(url);
            }

            addToCart = async() => {
                let price = this.props.product.priceIncl;
                let reseller = false;

                if (parseInt(this.storeAuth.user.isReseller) === 1) {
                    price = this.state.priceDiscounted;
                    reseller = true;
                } else {
                    price = this.props.product.priceIncl; 
                    reseller = false;
                }

                const added = await this.storeCart.addToCart(this.props.product, price, reseller);
                const inCart = await this.checkInCart();

                this.storeUi.openDialogAddedToCart();
            }

            checkInCart = async() => {
                let inCart = false;
                let cart;
                let nrItems;

                const cartId = this.storeCart.cartId;
                cart = await this.storeCart.getCartItems(cartId);

                if (cart) {
                    const sku = this.props.product.sku;
                    const idx = cart.findIndex(item => item.sku == sku);

                    if (idx !== -1) {
                        inCart =  true;
                    }
                }
                
                this.setState({
                    inCart: inCart
                })
            }
        
            render() {
                return (
                    parseInt(this.storeAuth.user.isReseller) === 1 
                    ?   <div
                            key = {this.props.key}
                            className = "wrapper-product product-price"
                        >
                            <button
                                className = {"button btn--primary --active" + (this.state.inCart ? " --incart" : "")}
                                onClick={() => this.addToCart()}
                            >
                                {this.state.inCart 
                                ? "Added to cart" 
                                : "Add to cart"
                                }
                            </button>
                        </div>
                    :   <div
                            key = {this.props.key}
                            className = "wrapper-product product-price"
                        >
                            <button
                                className = {"button btn--primary --active" + (this.state.inCart ? " --incart" : "")}
                                onClick={() => this.addToCart()}
                            >
                                {this.state.inCart 
                                ? "Added to cart" 
                                : "Add to cart"
                                }
                            </button>
                        </div>
                )
            }
        }
    )
)

export default withRouter(ProductPriceButton);
