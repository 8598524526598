import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import Template from '../../templates/template';
import ContentBanktansfer from './content-banktansfer';

const PaymentResult = inject("stores") (
    observer (
        class PaymentResult extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storePageContent = this.props.stores.storePageContent;
                this.storeUi = this.props.stores.storeUi;
                this.storeCart = this.props.stores.storeCart;
                this.storeOrders = this.props.stores.storeOrders;

                this.state = {
                    orderId: 0,
                    btn_class: '--active',
                    orderStatus: 'processing',
                    paymentStatus: 'banktransfer'
                }
            }

            componentDidMount = async() => {
                let page = "payment-result";
                let totalCosts = 0;
                let orderStatus = '';
                let orderId;

                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);

                page = "payment-banktransfer";
                const blocksPaymentBanktransfer = await this.storePageContent.getPageContent(page);
                page = "payment-received";
                const blocksPaymentReceived = await this.storePageContent.getPageContent(page);

                const searchString = this.props.location.search;
                const params = queryString.parse(searchString)
               
                if (params) {
                    if ('orderId' in params) {
                        orderId = params.orderId;
                    }
                    if ('orderid' in params) {
                        orderId = params.orderid;
                    }
                }
                
                if (parseInt(orderId) !== 0) {
                    const order = await this.storeOrders.getOrderTotals(orderId);
   
                    if (order) {
                        totalCosts = order[0]['orderTotal'];
                    }
                    orderStatus = await this.storeOrders.getOrderStatus(orderId);

                    if (orderStatus !== 'bankTransfer') {
                        orderStatus = 'paymentCompleted';
                        const status = await this.storeOrders.setOrderStatus(orderId, orderStatus);
                    } 

                    const fb = await this.storeOrders.sendOrderConfirmation(orderId, orderStatus);
                    this.clearCart();
                }
                
                this.setState({
                    title: title,
                    blocks: blocks,
                    blocksPaymentBanktransfer: blocksPaymentBanktransfer,
                    blocksPaymentReceived: blocksPaymentReceived,
                    orderId: orderId,
                    orderStatus: orderStatus,
                    totalCosts: totalCosts
                })
            }
            
            componentDidUpdate = () => {
               
            }

            componentWillUnmount = () => {
               this.storeOrders.removeOrderId();
               this.storeCart.resetCart();
            }

            clearCart = () => {
                this.storeCart.resetCart();
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        title = {''}
                    >   
                        <React.Fragment>
                            <div
                                className = "wrapper-content"
                            >
                                {this.state.blocks && this.state.blocks.map((block, i) => (
                                    <div
                                        key = {i}
                                    >
                                        <p
                                             dangerouslySetInnerHTML={{ __html: block.textEN}}
                                        />
                                    </div>
                                ))}

                                {this.state.orderId !== 0 &&
                                <React.Fragment>
                                {(this.state.orderStatus === 'processing') &&
                                    <div>
                                        <h1>Processing order status ...</h1>
                                    </div>
                                }

                                {(this.state.orderStatus !== undefined && this.state.orderStatus !== '' && this.state.orderStatus === 'bankTransfer') &&
                                    <div>
                                        <h1>{this.state.title}</h1>
                                        <p>Your order number is:&nbsp;&nbsp;<strong>{this.state.orderId}</strong><br/>
                                        The total amount is: <strong>&euro; {this.state.totalCosts}</strong></p>
                                        {this.state.blocksPaymentBanktransfer && this.state.blocksPaymentBanktransfer.map((block, i) => (
                                            <div
                                                key = {i}
                                            >
                                                <p
                                                    dangerouslySetInnerHTML={{ __html: block.textEN}}
                                                />
                                            </div>
                                        ))}
                                        <ContentBanktansfer
                                            orderId = {this.state.orderId}
                                            totalCosts = {this.state.totalCosts}
                                        />
                                    </div>
                                }

                                {(this.state.orderStatus !== undefined && this.state.orderStatus !== '' && this.state.orderStatus !== 'bankTransfer') &&
                                    <div>
                                        <h1>{this.state.title}</h1>
                                        <p>Your order number is:&nbsp;&nbsp;<strong>{this.state.orderId}</strong></p>
                                        {this.state.blocksPaymentReceived && this.state.blocksPaymentReceived.map((block, i) => (
                                            <div
                                                key = {i}
                                            >
                                                <p
                                                    dangerouslySetInnerHTML={{ __html: block.textEN}}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                }
                                </React.Fragment>
                                }

                                {(this.state.orderStatus !== 'processing' && (this.state.orderStatus === undefined || this.state.orderStatus === '' || this.state.orderId === 0)) &&
                                    <div>
                                        <h1>Unfortunately an error occurred</h1>
                                        <p>We couldn't determine the status of your payment.<br/>
                                        A technical error has occurred or your payment may not have succeeded.</p>
                                        <p>If you are in doubt, please contact us to resolve this matter at:<br/>
                                        <a className="--link" href="mailto:info@abanda.nl">info@abanda.nl</a><br/>
                                        or by filling in the <Link className="--link" to="/contact">contact form</Link>.
                                        </p>
                                    </div>
                                }
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(PaymentResult));
