import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import Template from '../templates/template';
import ProductPrice from '../components/shop/product-price';
import ProductPriceButton from '../components/shop/product-price-button';

const Product = inject("stores") (
    observer (
        class Product extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    redirectISBN: false,
                    redirectPayment: false,
                    id_quote: 0
                }
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_API_PUBLIC;

                this.storePageContent = this.props.stores.storePageContent;
                this.storeProducts = this.props.stores.storeProducts;
            }

            componentDidMount = async() => {
                const id = this.props.params.id;
                const blocks = await this.storePageContent.getPageContent('product');
                const product = await this.storeProducts.getProduct(id);

                this.setState({
                    blocks: blocks,
                    product: product
                })
            }

            componentDidUpdate = () => {
               
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        title = {this.state.product && this.state.product.title}
                        subtitle = {this.state.product && this.state.product.subtitle}
                    >   
                        <React.Fragment>
                            {this.state.blocks && this.state.blocks.map((block, i) => (
                                <div
                                    className = "wrapper-content"
                                >
                                    <div
                                        key = {i}
                                    >
                                        <p
                                            dangerouslySetInnerHTML={{ __html: block.textEN}}
                                        />
                                    </div>
                                </div>
                            ))}
                            
                            {this.state.product &&
                                <div
                                    className = "wrapper-content wrapper-product"
                                >
                                    <div
                                        className = "wrapper-content__columns"
                                    >
                                        <div
                                            className = "wrapper-content__column"
                                        >
                                            {this.state.product.sku === '9789079872006' &&
                                                <img src={this.urlPublic + "/public/images/product/v2/" + this.state.product.sku + "-1.jpg"} />
                                            }
                                            {this.state.product.sku === '9789079872021' &&
                                                <img src={this.urlPublic + "/public/images/product/v2/" + this.state.product.sku + ".jpg"} />
                                            }
                                            
                                            {this.state.product.discount === 0 &&
                                                <ProductPrice
                                                    product = {this.state.product}
                                                    view = {'regular'}
                                                />
                                            }
                                            <ProductPrice
                                                product = {this.state.product}
                                                view = {'discount'}
                                            />
                                            <ProductPrice
                                                product = {this.state.product}
                                                view = {'wholesale'}
                                            />
                                            <ProductPriceButton
                                                product = {this.state.product}
                                            />
                                        </div>
                                        <div
                                            className = "wrapper-content__column"
                                        >
                                            <div
                                                dangerouslySetInnerHTML={{ __html: this.state.product.summary}}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className = "wrapper-content__columns"
                                    >
                                        <div
                                            className = "wrapper-content__column"
                                        >
                                            <h2>Specifications</h2>
                                            <ul>
                                                <li>
                                                    <span>Weight&nbsp;</span>
                                                    <span>{this.state.product.weight} gr.</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div
                                            className = "wrapper-content__column"
                                        >
                                            <h2>Description</h2>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: this.state.product.description}}
                                            />
                                        </div>
                                    </div>

                                </div>
                            }
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Product));
