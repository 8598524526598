import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import Template from '../templates/template';

import FormContact from '../components/forms/form-contact';

const Contact = inject("stores") (
    observer (
        class Contact extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    redirectISBN: false,
                    redirectPayment: false,
                    id_quote: 0
                }
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storePageContent = this.props.stores.storePageContent;
                this.storeUser = this.props.stores.storeUser;
            }

            componentDidMount = async() => {
                const page = "contact";
                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);

                const user = await this.getUser();

                this.setState({
                    title: title,
                    blocks: blocks,
                    user: user
                })
            }

            componentDidUpdate = () => {

            }

            getUser = async() => {
                const id = this.storeAuth.user.id;
                const email = this.storeAuth.user.email;
                const user = await this.storeUser.getUserData(id, email);

                return user;
            }

            render() {

                return (                  
                    <Template
                        type = 'default'
                        title = {this.state.title && this.state.title}
                    >   
                        <React.Fragment>
                            <div
                                className = "wrapper-content"
                            >
                                {this.state.blocks && this.state.blocks.map((block, i) => (
                                    <div
                                        key = {i}
                                    >
                                        <p
                                             dangerouslySetInnerHTML={{ __html: block.textEN}}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div
                                className = "wrapper-content"
                            >
                                <FormContact 
                                    user = {this.state.user}
                                />
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Contact));
