import React, { Component } from 'react';
import { runInAction, reaction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import RowText from '../form-rows/row-text';
import Select from '../../elements/form-input-select';
import RowSelect from '../form-rows/row-select';

const FormShipping = inject("stores") (
    observer (
        class FormShipping extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;
                this.storeCart = this.props.stores.storeCart;
                this.storeShipping = this.props.stores.storeShipping;

                this.fieldTitles = [
                    'address_shipping'
                ];

                this.state = {
                    form: 'shipping',
                    formControls: { 
                        shippingFirstname: {
                            type: "text",
                            group: 1,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.addresses.shippingFirstname 
                                    ?   this.props.addresses.shippingFirstname 
                                    :   this.props.user.invoiceFirstname
                                :   '',
                            validationRules: { 
                            },
                            valid: true
                        },
                        shippingMiddlename: {
                            type: "text",
                            group: 1,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceMiddlename
                                :   '',
                            validationRules: {
                            },
                            valid: true
                        }, 
                        shippingLastname: {
                            type: "text",
                            group: 1,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.invoiceLastname
                                :   '',
                            validationRules: {

                            },
                            valid: true
                        },
                        shippingAddress: {
                            type: "text",
                            group: 3,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.address
                                :   '',
                            validationRules: {  
                                isRequired: true
                            },
                            valid: true
                        },
                        shippingHousenr: {
                            type: "text",
                            group: 3,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.housenr
                                :   '',
                            validationRules: {  

                            },
                            valid: true
                        },
                        shippingAddition: {
                            type: "text",
                            group: 3,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.addition
                                :   '',
                            validationRules: {  
                                
                            },
                            valid: true
                        },
                        shippingZipcode: {
                            type: "text",
                            group: 3,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.zipcode
                                :   '',
                            validationRules: {  

                            },
                            valid: true
                        },
                        shippingCity: {
                            type: "text",
                            group: 3,
                            value: this.storeAuth.isLoggedin 
                                ?   this.props.user.city
                                :   '',
                            validationRules: {  
                                isRequired: true
                            },
                            valid: true
                        },
                        shippingCountry_select: {
                            type: "select",
                            group: 3,
                            label: i18n.t("forms.label.country"),
                            value: this.storeShipping.shippingCountryCode,
                            placeholder: i18n.t("forms.placeholder.country"),
                            options: this.storeGeneral.listCountries,
                            validationRules: {  
                                isRequired: true
                            },
                            valid: true
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled',
                    error: false,
                    classFeedback: '',
                    cartAddresses: {}
                }
            }

            componentDidMount = () => {
                this.initCartAddresses();
            }

            componentWillUnmount = () => {
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.user !== this.props.user) {
                    let updatedControls = this.state.formControls;

                    Object.keys(updatedControls).map((control, i) => {
                        if (control in this.props.user) { 
                            updatedControls[control].value = this.props.user[control];
                        }
                    });

                    this.setState({
                        formControls: updatedControls,
                    }); 
                }
            }

            initCartAddresses = () => {
                let updatedControls = this.state.formControls;

                Object.keys(updatedControls).map((control, i) => {
                    if (control in this.props.addresses) { 
                        updatedControls[control].value = this.props.addresses[control];

                        if (control === 'shippingAddress' && updatedControls[control].value !== '') {
                            this.storeShipping.setHasShippingAddress(true);
                        }
                    }
                });

                this.setState({
                    formControls: updatedControls
                }); 
            }

            validateRow = (name, value, valid, touched) => {  
                this.storeUi.clearFormFeedback();
                let updatedControls;

                if (name === 'shippingAddress' && value !== '') {
                    this.storeShipping.setHasShippingAddress(true);
                    this.storeShipping.setShippingCountryCode(this.state.formControls.shippingCountry_select.value);
                }
                if (name === 'shippingAddress' && (value === '' || value === null || value === undefined)) {
                    this.storeShipping.setHasShippingAddress(false);
                }

                updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid, touched);

                this.setState({
                    error: null,
                    formControls: updatedControls,
                }, () => this.updateCartAddressShipping()); 
             }

            handleDropdown = (action, countryCode) => {
                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls['shippingCountry_select']
                };
                updatedFormElement.value = countryCode;
                updatedControls['shippingCountry_select'] = updatedFormElement;

                this.setState({
                    notificationCountry: action === 'auto' 
                    ?   (countryCode !== this.state.formControls.shippingCountry_select.value && (countryCode === 'NL' || countryCode === 'BE')) ? true : false
                    :   false,
                    formControls: updatedControls
                }, () => this.updateCartAddressShipping()); 
            }

            handleBlur = () => {

            }

            updateCartAddressShipping = async() => {
                const registered = await this.storeCart.updateCartAddressShipping(this.state.formControls);
                //this.storeShipping.setShippingCountryCode(this.state.formControls.shippingCountry_select.value);
                
                if (this.state.formControls.shippingAddress.value !== '' && this.state.formControls.shippingCity.value !== '' && this.state.formControls.shippingCountry_select.value !== '' && this.state.formControls.shippingCountry_select.value !== '00') {
                    this.storeCart.setShippingCountryCode(this.state.formControls.shippingCountry_select.value);
                    this.storeShipping.setHasShippingAddress(true);
                } else {
                    this.storeCart.setShippingCountryCode('');
                    this.storeShipping.setHasShippingAddress(false);
                }

                this.setState({
                    reset: false
                });
            }

            getCartAddresses = async() => {
                const cartAddresses = await this.storeCart.getCartAddresses();

                this.setState({
                    cartAddresses: cartAddresses
                })
            }

            clearShippingAddress = (e) => {
                e.preventDefault();
                this.storeShipping.setHasShippingAddress(false);
                this.storeCart.setShippingCountryCode('');

                let updatedControls = {
                    ...this.state.formControls
                };

                Object.keys(this.state.formControls).map((key, i) => {
                    const updatedFormElement = {
                        ...updatedControls[key]
                    };

                    updatedFormElement.value = '';
                    updatedFormElement.touched = false;
                    updatedControls[key] = updatedFormElement;

                    
                });

                this.setState({
                    formControls: updatedControls,
                    reset: true
                }, () => this.updateCartAddressShipping());
            }

            render() {
                return (
                    <form className="form form--register">
                        <div className="form-fieldset">
                            <div
                                className = "form__fieldset-title"
                                dangerouslySetInnerHTML={{ __html: i18n.t("form_fieldset-title." + this.fieldTitles[0])}}
                            />

                            <div className="form-row form-row--buttons">
                                <button 
                                    className={"btn btn--tertiary --active --fixed"} 
                                    onClick={(e) => this.clearShippingAddress(e)}
                                >
                                    Clear shipping address
                                </button>
                            </div>
                            <span className="form-field--clue">A shipping address, city and country is required for the shipping address to be taken into account.</span>
                           
                            {Object.keys(this.state.formControls).map((key, i) => (
                                <React.Fragment>
                                    {this.state.formControls[key].type === "text" &&
                                        <RowText 
                                            type = {this.state.formControls[key].type}
                                            name = {key}
                                            value = {this.state.formControls[key].value}
                                            validationRules = {this.state.formControls[key].validationRules}
                                            validationMsg = {this.state.formControls[key].validationMsg}
                                            onValidate = {this.validateRow}
                                            onBlur = {this.handleBlur}
                                            disable = {this.state.formControls[key].disable}
                                            readonly = {this.state.formControls[key].readonly}
                                            touched = {this.state.formControls[key].touched}
                                            reset = {this.state.reset}
                                        />
                                    }

                                    {(key === "shippingAddress" && this.state.formControls.shippingAddress.value === '' && this.state.formControls['shippingAddress'].touched) &&
                                        <span className="form-field--error">A shipping address is required for the shipping address to be taken into account.</span>
                                    }

                                    {(key === "shippingCity" && this.state.formControls.shippingCity.value === '' && this.state.formControls['shippingCity'].touched) &&
                                        <span className="form-field--error">A shipping city is required for the shipping address to be taken into account.</span>
                                    }

                                    {key === "shippingCountry_select" &&
                                        <React.Fragment>
                                            {/* <Select 
                                                name="country_select"
                                                value = {this.state.formControls[key].value}
                                                options = {this.state.formControls[key].options ? this.state.formControls[key].options : []}
                                                touched = {this.state.formControls[key].touched}
                                                onChange = {(e) => this.handleDropdown('dd', e.target.value)}
                                                valueSelected = {this.state.formControls[key].value}
                                                defaultValue = {this.state.formControls[key].value}
                                                langage = {this.storeGeneral.language}
                                            /> */}

                                            <RowSelect 
                                                name = {key}
                                                label = {this.state.formControls[key].label}
                                                value = {this.state.formControls[key].value}
                                                options = {this.state.formControls[key].options ? this.state.formControls[key].options : []}
                                                validationRules = {this.state.formControls[key].validationRules}
                                                onValidate = {this.validateRow}
                                                selected = {this.state.formControls[key].value}
                                                defaultValue = {this.state.formControls[key].value}
                                                touched = {this.state.formControls[key].touched}
                                            />
                                            
                                            {((this.state.formControls.shippingCountry_select.value === '' || this.state.formControls.shippingCountry_select.value === '00') && this.state.formControls['shippingCountry_select'].touched) &&
                                                <span className="form-field--error">Select a country for the shipping address to be taken into account.</span>
                                            }
                                        </React.Fragment>
                                    }                                                
                                </React.Fragment>
                            ))}
                        </div>
                    </form>
                )
            }
        }
    )
)

export default withTranslation()(FormShipping);
