import React, { Component } from 'react';
import { runInAction, reaction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import validate from '../../utilities/validation-rules';
import * as ui from '../../utilities/ui';

import TemplateForms from '../../templates/template-forms';
import RowText from '../form-rows/row-text';
import Select from '../../elements/form-input-select';

const FormAccount = inject("stores") (
    observer (
        class FormAccount extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;
                this.storeCart = this.props.stores.storeCart;

                this.fieldTitles = [
                    'profile',
                    'company',
                    'address',
                    'reset_password'
                ];

                this.state = {
                    form: 'accountupdate',
                    formControls: { 
                        invoiceFirstname: {
                            type: "text",
                            group: 1,
                            value: this.props.user ? this.props.user.invoiceFirstname : '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        invoiceMiddlename: {
                            type: "text",
                            group: 1,
                            value: this.props.user ? this.props.user.invoiceMiddlename : '',
                            validationRules: {
                            },
                            valid: true
                        }, 
                        invoiceLastname: {
                            type: "text",
                            group: 1,
                            value: this.props.user && this.props.user.invoiceLastname,
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },         
                        invoiceEmail: {
                            type: "email",
                            group: 1,
                            value: this.props.user && this.props.user.invoiceEmail,
                            validationRules: {
                                isRequired: this.storeAuth.isLoggedin ? false : true,
                                isEmail: true
                            },
                            validationMsg: i18n.t("form_errors.email"),
                            valid: this.storeAuth.isLoggedin ? true : false,
                            readonly: this.storeAuth.isLoggedin ? true : false
                        },
                        invoiceEmail2: {
                            type: "email",
                            group: 1,
                            value: this.props.user && this.props.user.invoiceEmail,
                            validationRules: {
                                isRequired: this.storeAuth.isLoggedin ? false : true,
                                isEmail: true
                            },
                            validationMsg: i18n.t("form_errors.email"),
                            valid: this.storeAuth.isLoggedin ? true : false,
                            disable: ["paste"]
                        },
                        invoicePhonenr: {
                            type: "text",
                            group: 1,
                            value: this.props.user && this.props.user.invoicePhonenr,
                            validationRules: {  
                                isRequired: true,
                                isPhoneNumber: true
                            },
                            valid: true
                        },
                        invoiceCompany: {
                            type: "text",
                            group: 2,
                            value: this.props.user && this.props.user.invoiceCompany,
                            validationRules: {  
                            },
                            valid: true
                        },
                        invoiceVATnr: {
                            type: "text",
                            group: 2,
                            value: this.props.user && this.props.user.VATnr,
                            validationRules: {  
                            },
                            valid: true
                        },
                        invoiceAddress: {
                            type: "text",
                            group: 3,
                            value: this.props.user && this.props.user.invoiceAddress,
                            validationRules: {  
                                
                            },
                            valid: true
                        },
                        invoiceHousenr: {
                            type: "text",
                            group: 3,
                            value: this.props.user && this.props.user.invoiceHousenr,
                            validationRules: {  
                                
                            },
                            valid: true
                        },
                        invoiceAddition: {
                            type: "text",
                            group: 3,
                            value: this.props.user && this.props.user.invoiceAddition,
                            validationRules: {  
                                
                            },
                            valid: true
                        },
                        invoiceZipcode: {
                            type: "text",
                            group: 3,
                            value: this.props.user && this.props.user.invoiceZipcode,
                            validationRules: {  
                                
                            },
                            valid: true
                        },
                        invoiceCity: {
                            type: "text",
                            group: 3,
                            value: this.props.user && this.props.user.invoiceCity,
                            validationRules: {  
                                
                            },
                            valid: true
                        },
                        invoiceCountry_select: {
                            type: "select",
                            group: 3,
                            label: i18n.t("forms.label.country"),
                            value: (this.props.user && this.props.user.invoiceCountryCode === '') || (this.props.user && this.props.user.invoiceCountryCode === null)
                                ?   'NL'
                                :   this.props.user && this.props.user.invoiceCountryCode,
                            placeholder: i18n.t("forms.placeholder.country"),
                            options: this.storeGeneral.listCountries,
                            validationRules: {},
                            valid: true
                        },
                        password: {
                            type: "password",
                            group: 4,
                            value: '',
                            validationRules: 
                                this.storeAuth.isLoggedin ? {} : {minLength: 5}
                            ,
                            validationMsg: 'ruleMsg',
                            valid: this.storeAuth.isLoggedin ? true : false
                        },
                        password2: {
                            type: "password",
                            group: 4,
                            value: '',
                            validationRules: 
                                this.storeAuth.isLoggedin ? {} : {minLength: 5}
                            ,
                            validationMsg: 'ruleMsg',
                            disable: ["paste"],
                            valid: this.storeAuth.isLoggedin ? true : false
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled',
                    error: false,
                    error_email: false,
                    error_email2: false,
                    error_email_match: false,
                    error_pw_match: false,
                    classFeedback: ''
                }
            }

            componentDidMount = () => {
                //this.storeUser.clearFormFeedback();
                //console.log('SbC user', this.props.user)
                this.initForm();
            }

            componentWillUnmount = () => {
                //this.storeUser.clearFormFeedback();
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.user !== this.props.user) {
                    let updatedControls = this.state.formControls;

                    Object.keys(updatedControls).map((control, i) => {
                        if (control in this.props.user) { 
                            updatedControls[control].value = this.props.user[control];
                        }
                    });

                    if (this.storeAuth.isLoggedin) {
                        updatedControls['invoiceEmail2'].value = this.props.user['invoiceEmail'];
                    }

                    this.setState({
                        formControls: updatedControls,
                    }, () => this.initForm()); 
                }
            }

            initForm = () => {
                const updatedControls = this.storeForms.initForm(this.state.formControls);

                this.setState({
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateRow = (name, value, valid, touched) => {  
                this.storeUser.clearFormFeedback();
                let updatedControls;

                if (name !== 'password' && name !== 'password2') {
                    updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid, touched);

                    this.setState({
                        error: null,
                        formControls: updatedControls,
                    }, () => this.validateForm()); 
                } else {
                    if (name === 'password' && (value !== this.state.formControls.password2.value)) {
                        updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, false, true);
                    } else {
                        updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, true, false);
                    }

                    this.setState({
                        formControls: updatedControls,
                    }, () => this.validateForm()); 
                }
            }

            validateForm = async() => {
                let matchingEmail;
                let error_email_match;

                let matchingPw;
                let error_pw_match;

                let emailAvailable = true;
                let emailAvailable2 = true;

                // if (this.state.formControls.email.value !== '' && this.state.formControls.email2.value !== '') {
                //     matchingEmail = ui.checkMatchingValues(this.state.formControls.email.value, this.state.formControls.email2.value);
                //     error_email_match = !matchingEmail;
                // }
                matchingEmail = true;

                if (this.state.formControls.password.value !== '' || this.state.formControls.password2.value !== '') {
                    matchingPw = ui.checkMatchingValues(this.state.formControls.password.value, this.state.formControls.password2.value);
                    error_pw_match = !matchingPw;
                } else {
                    error_pw_match = false;
                    matchingPw = true;
                }

                const formIsValid = matchingEmail && matchingPw && emailAvailable && emailAvailable2 && this.storeForms.validateForm(this.state.formControls);
               
                this.setState({
                    //error_firstname: this.state.formControls.invoiceFirstname.value.length >= 2 ? false : this.state.error_firstname,
                    error_email: !emailAvailable,
                    error_email2: !emailAvailable2,
                    error_email_match: error_email_match,
                    error_pw_match: error_pw_match,
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                });
            }

            async checkEmail(value) {
                let emailAvailable = await this.storeUser.checkEmailExists(value);
                return emailAvailable;    
            }

            handleBlur = () => {
                this.setState({
                    error_firstname: (this.state.formControls.invoiceFirstname.value !== '' && (this.state.formControls.invoiceFirstname.value && this.state.formControls.invoiceFirstname.value.length === 1)) ? true : false
                })
            }

            handleDropdown = (action, countryCode) => {
                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls['invoiceCountry_select']
                };
                updatedFormElement.value = countryCode;
                updatedControls['invoiceCountry_select'] = updatedFormElement;

                this.setState({
                    notificationCountry: action === 'auto' 
                    ?   (countryCode !== this.state.formControls.invoiceCountry_select.value && (countryCode === 'NL' || countryCode === 'BE')) ? true : false
                    :   false,
                    formControls: updatedControls
                }, () => this.validateForm()); 
            }

            handleFeedback = () => {

            }

            getCountryValue = (countryCode) => {
                let value;

                const idx = this.storeGeneral.listCountries.findIndex(country => country.code == countryCode);
                value = this.storeGeneral.listCountries[idx].name;

                return value;
            }

            updateUser = async(e) => {
                e.preventDefault();
                if (this.state.formIsValid && this.state.btn_class !== '--disabled') {
                    this.setState({
                        btn_class: '--disabled'
                    })

                    const country = this.getCountryValue(this.state.formControls.invoiceCountry_select.value);
                    let registered = await this.storeUser.updateUser(this.state.formControls, country, this.state.form);
                    this.storeCart.setInvoiceCountryCode(this.state.formControls.invoiceCountry_select.value);

                    // if ('success' in this.storeUser.formFeedback) {
                    //     this.handleFeedback();
                    // }
                }
            }

            handleClose = (e) => {
                this.setState({
                    classFeedback: '--inline'
                })
            }

            render() {
                return (
                    <TemplateForms
                        formClass = "register"
                        type = 'register'
                        msg_success = {i18n.t("form_feedback.success_register")}
                    >
                        <form className="form form--register">
                            {[...Array(4)].map((x, idx) =>
                            <React.Fragment>
                                {(idx === 0 && this.storeAuth.user.isReseller) 
                                    ?   <div className="form-fieldset form-fieldset--notification">
                                            You have been approved as wholesale customer.<br/>
                                            The appropriate discounts are applied in the shop.
                                        </div>
                                    : null
                                }
                                {(idx === 0 && this.storeAuth.user.isApproved === 2) 
                                    ?   <div className="form-fieldset form-fieldset--notification">
                                            Your application as wholesale reseller is pending.<br/>
                                            As soon as your application is approved, the appropriate discounts will show in the shop.
                                        </div>
                                    : null
                                }
                                <div className="form-fieldset">
                                    <div
                                        className = "form__fieldset-title"
                                        dangerouslySetInnerHTML={{ __html: i18n.t("form_fieldset-title." + this.fieldTitles[idx])}}
                                    />
                                    {idx === 3 &&
                                        <span
                                            className = "form__fieldset-subtitle"
                                            dangerouslySetInnerHTML={{ __html: i18n.t("form_fieldset-subtitle." + this.fieldTitles[idx])}}    
                                        />
                                    }
                                    {Object.keys(this.state.formControls).map((key, i) => (
                                        (idx + 1) === this.state.formControls[key].group &&
                                            <React.Fragment>
                                                {(this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email" || this.state.formControls[key].type === "password") &&
                                                    (this.storeAuth.isLoggedin && key !== 'invoiceEmail2') &&
                                                        <RowText 
                                                            type = {this.state.formControls[key].type}
                                                            name = {key}
                                                            value = {this.state.formControls[key].value}
                                                            validationRules = {this.state.formControls[key].validationRules}
                                                            validationMsg = {this.state.formControls[key].validationMsg}
                                                            onValidate = {this.validateRow}
                                                            onBlur = {this.handleBlur}
                                                            disable = {this.state.formControls[key].disable}
                                                            readonly = {this.state.formControls[key].readonly}
                                                        />
                                                }

                                                {key === "invoiceCountry_select" &&
                                                    <div className="form-row form-row--select">
                                                        <div className="form-column">
                                                            {this.state.formControls[key].label}
                                                        </div>
                                                        <div className="form-column">
                                                            <Select 
                                                                name="country_select"
                                                                value = {this.state.formControls[key].value}
                                                                options = {this.state.formControls[key].options ? this.state.formControls[key].options : []}
                                                                touched = {this.state.formControls[key].touched}
                                                                onChange = {(e) => this.handleDropdown('dd', e.target.value)}
                                                                valueSelected = {this.state.formControls[key].value}
                                                                defaultValue = {this.state.formControls[key].value}
                                                                language = {this.storeGeneral.language}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                               
                                                {key === "firstname" && this.state.error_firstname &&
                                                    <span className="form-field--suggestion">{i18n.t("form_suggestions.firstname")}</span>
                                                }

                                                {((key === "email" && this.state.error_email) || (key === "email2" && this.state.error_email2)) &&
                                                    <span className="form-field--error --email">{i18n.t("form_errors.email_in_use_extended")}</span>
                                                }

                                                {key === "email2" && this.state.error_email_match &&
                                                    <span className="form-field--error --email">{i18n.t("form_errors.email_not_matching")}</span>
                                                }

                                                {key === "password2" && this.state.error_pw_match &&
                                                    <span className="form-field--error --pw">{i18n.t("form_errors.pw_not_matching")}</span>
                                                }

                                                
                                            </React.Fragment>
                                    ))}

                                    {this.state.error &&
                                        <div className="form-row form-row--error">
                                            <p>{i18n.t("errors.error_general")}</p>
                                        </div>
                                    }
                                </div>
                            </React.Fragment>
                            )}
                        </form>

                        <div className="form-row form-row--buttons">
                            <button 
                                className={"btn btn--primary " + this.state.btn_class} 
                                onClick={(e) => this.updateUser(e)}
                            >
                                {i18n.t("button.update_profile")}
                            </button>
                        </div>
                                    
                        <div className="form__feedback">
                            {'success' in this.storeUser.formFeedback &&
                                <span>{i18n.t("form_feedback.account_update")}</span>
                            }
                            {'error' in this.storeUser.formFeedback &&
                                <span>{i18n.t("form_feedback.account_error")}</span>
                            }
                        </div>
                    </TemplateForms>
                )
            }
        }
    )
)

export default withTranslation()(FormAccount);
