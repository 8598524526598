import * as apifetch from './fetch.js';

export async function getCountries() {
    const urlEndpoint = '/v2/tools/countries';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getShippingCosts(weight) {
    const urlEndpoint = '/v2/calculations/shipping';

    const postData = {
        weight: weight   
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

// export async function getShippingCostsV2(orderId) {
//     const urlEndpoint = '/v2/calculations/shipping/' + orderId;

//     const postData = {
//         orderId: orderId   
//     }

//     return apifetch.sbFetch(urlEndpoint, 'POST', postData);
// }
