import React, { Component } from 'react';
import { reaction, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import RowText from '../form-rows/row-text';
import FormFeedbackRequired from '../../elements/forms/form-feedback-required';
import TemplateForms from '../../templates/template-forms';

const FormPasswordReset = inject("stores") (
    observer (
        class FormPasswordReset extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    formControls: {          
                        password: {
                            type: "password",
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        password2: {
                            type: "password",
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled'
                }
            }

            validateRow = (name, value, valid) => { 
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);
                this.storeUi.clearFormFeedback();
                
                this.setState({
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    btn_class: formIsValid 
                        ?   '--active' 
                        :   '--disabled'
                });
            }

            resetPassword = async(e) => {
                if (this.state.formIsValid) {
                    let login = await this.storeAuth.resetPassword(this.state.formControls);
                    if (!('error' in login)) {
                        this.storeAuth.setLoginState(login);
                     
                        this.props.onNext();
                    } else {

                    }
                }
            }

            render() {

                return (
                    <React.Fragment>
                        <h2>
                            Reset your password
                        </h2>
                        <p>
                            Due to the change in our system, a reset of your password is necessary.<br/>
                            Please fill in and confirm your new password.
                        </p>
                        
                        <TemplateForms
                            formClass = "login"
                            type = 'login'
                            msg_success = {i18n.t("form_feedback.success_login")}
                        >
                            <form className="form">
                                <div className="form-fieldset">
                                    {Object.keys(this.state.formControls).map((key, i) => (
                                        <React.Fragment>
                                            {(this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email" || this.state.formControls[key].type === "password") &&
                                                <RowText 
                                                    type = {this.state.formControls[key].type}
                                                    name = {key}
                                                    value = {this.state.formControls[key].value}
                                                    validationRules = {this.state.formControls[key].validationRules}
                                                    onValidate = {this.validateRow}
                                                />
                                            }
                                        </React.Fragment>
                                    ))}
                                </div>
                            </form>
                                
                            <div className="form-row form-row--buttons">
                                <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.resetPassword(e)}>{i18n.t("button.reset")}</button>
                            </div>
                                
                            {/* {'error' in this.storeUi.form_feedback && (
                                <div className="form-row form-row--error">
                                    <p 
                                        dangerouslySetInnerHTML={{ __html: i18n.t("errors.error_login")}}
                                    />
                                </div>
                            )} */}
                        </TemplateForms>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(FormPasswordReset);
