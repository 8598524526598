import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "../withRouter";
import { withTranslation } from 'react-i18next';

import Template from '../templates/template';

const ProductInformation = inject("stores") (
    observer (
        class ProductInformation extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    blocks: []
                }
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_API_PUBLIC;

                this.storeProducts = this.props.stores.storeProducts;
            }

            componentDidMount = async() => {
                const blocks = await this.storeProducts.getProductInfo();

                this.setState({
                    blocks: blocks
                })
            }

            componentDidUpdate = () => {
               
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        title = {'Product information'}
                    >   
                        <div
                            className = "wrapper-content"
                        >
                            {this.state.blocks && this.state.blocks.map((block, i) => (
                                <div
                                    key = {i}
                                >
                                    <h2>{block.title}</h2>
                                    <p
                                            dangerouslySetInnerHTML={{ __html: block.textEN}}
                                    />
                                </div>
                            ))}
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(ProductInformation));
