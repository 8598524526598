import React from 'react';

const Select = props => {

    let formControl = "form-control";

    return (
        <div className="form-group">
            <select 
                className = {formControl} 
                value = {props.defaultValue} 
                onChange = {props.onChange} 
                name = {props.name}
            >
                {props.options.map(option => (
                    <option 
                        value = {option.value} 
                        key = {option.value}
                    >
                        {props.language === 'NL'
                            ?   option.displayValue
                            :   option.name
                        }
                    </option>
                ))}
            </select>
        </div>
    );
}

export default Select;