import React, { Component }  from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "../../withRouter";

const OrderFilter = inject("stores") (
    observer (
        class OrderFilter extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeOrders = this.props.stores.storeOrders;

                this.state = {
                    active: 'all'
                }
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = async(prevProps) => {

            }

            handleGetOrders = async(filter) => {
                const orders = await this.storeOrders.getOrders(filter);

                this.setState({
                    active: filter
                })
            }

            render() {
                return (
                    <div className = 'order-filter'>
                        <ul className = 'order-filter__list'>
                            <li
                                className = 'order-filter__listitem'
                            >
                                View:
                            </li>
                            <li
                                className = {'order-filter__listitem --link' + (this.state.active === 'all' ? ' --active' : '')}
                                onClick = {() => this.handleGetOrders('all')}
                            >
                                All orders
                            </li>
                            <li
                                className = {'order-filter__listitem --link' + (this.state.active === 'open' ? ' --active' : '')}
                                onClick = {() => this.handleGetOrders('open')}
                            >
                                Open orders
                            </li>
                            <li
                                className = {'order-filter__listitem --link' + (this.state.active === 'closed' ? ' --active' : '')}
                                onClick = {() => this.handleGetOrders('closed')}
                            >
                                Completed orders
                            </li>
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(OrderFilter);

