import * as apifetch from './fetch.js';

export async function getPaymentMethods() {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/v2/payment/methods';

    try {
        let response = await fetch(urlEndpoint, {
            method: 'GET',
            headers: new Headers({
                
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error payment gateways: ', error.message);
    }
}

export async function getPaymentIssuers() {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/v2/payment/issuers';

    try {
        let response = await fetch(urlEndpoint, {
            method: 'GET',
            headers: new Headers({
                
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error payment issuers: ', error.message);
    }
}

export async function getPaymentLink(orderId, data) {
    const urlEndpoint = '/v2/payment/' + orderId + '/paymentlink';
   
    const postData = {
        data: data
    };

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function handlePaymentProcess(order_id, order_guid, payment_data, payment_method, payment_issuer) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/payment/process/processPayment.php';

    const postData = {
        order_id: order_id,
        order_guid: order_guid,
        payment_data: payment_data,
        payment_method: payment_method,
        payment_issuer: payment_issuer
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error payment process: ', error.message);
    }
}

export async function handleBankTransfer(order_id, payment_data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/v2/process/banktransfer/' + order_id;

    const postData = {
        order_id: order_id,
        payment_data: payment_data
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error banktransfer: ', error.message);
    }
}

export async function getOrderPaymentDetails(order_id) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/payment/process/getOrderPaymentDetails.php';

    const postData = {
        order_id: order_id
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson; 
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error payment details: ', error.message);
    }
}

export async function getOrderStatus(guid) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/payment/process/getOrderStatus.php';
   
    const postData = {
        guid: guid
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson; 
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error order status: ', error.message);
    }
}

export async function getPaymentStatus(orderId) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/payment/process/getPaymentStatus.php';
  
    const postData = {
        orderId: orderId
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson; 
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error order status: ', error.message);
    }
}