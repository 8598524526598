import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import Template from '../../templates/template';
import FormCheckout from '../../components/forms/form-checkout';
import CartView from '../../components/shop/cart-view';

const Checkout = inject("stores") (
    observer (
        class Checkout extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storePageContent = this.props.stores.storePageContent;
                this.storeUser = this.props.stores.storeUser;
                this.storeCart = this.props.stores.storeCart;
                this.storeShipping = this.props.stores.storeShipping;

                this.state = {
                    formIsValid: false,
                    btn_class: '--disabled',
                    hasCountries: false,
                    cartAddresses: {}
                }
            }

            componentDidMount = async() => {
                let countries = [];
                let user = {};
                let cartUser = {};
                let cartAddresses;

                if (this.storeGeneral.listCountries.length === 0) {
                    countries = await this.storeGeneral.getCountries();
                }

                const page = "checkout";
                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);
               
                if (this.storeAuth.isLoggedin) {
                    //if (this.storeCart.cartUser.email === '') {
                        user = await this.getUser();
                        
                        
                        cartUser = await this.storeCart.updateCartUser(user);
                        cartAddresses = await this.updateCartAddresses();
                    // } else {
                    //     user = this.storeCart.cartUser;
                    // }
                } else {
                    user = this.storeCart.cartUser;
                }

                if (this.storeCart.cartId !== 0) {
                    cartAddresses = await this.storeCart.getCartAddresses();
                }

                this.setState({
                    title: title,
                    blocks: blocks,
                    user: user,
                    cartAddresses: cartAddresses,
                    hasCountries: true
                })
            }
            
            componentDidUpdate = async(prevProps) => {
                // let user = {};
                let countries;

                if (this.storeGeneral.listCountries.length === 0) {
                    countries = this.storeGeneral.getCountries();
                }

                // if (prevProps.user !== this.props.user) {
                //     if (this.storeAuth.isLoggedin) {
                //         if (this.storeCart.cartUser.email === '') {
                //             user = await this.getUser();
                //             this.storeCart.updateCartUser(user);
                //             console.log('SbC user ...', user)
                //         } else {
                //             user = this.storeCart.cartUser;
                //         }
                //     } else {
                //         user = this.storeCart.cartUser;
                //     }

                //     this.setState({
                //         user: user
                //     })
                // }
            }

            getUser = async() => {
                const id = this.storeAuth.user.id;
                const email = this.storeAuth.user.email;
                const user = await this.storeUser.getUserData(id, email);

                return user;
            }

            gotoPage = (page) => {
                const url = "/" + page;
                this.props.navigate(url);
            }

            formIsValid = (isValid) => {
                if (isValid) {
                    this.setState({
                        formIsValid: true,
                        btn_class: '--active'
                    })
                } else {
                    this.setState({
                        formIsValid: false,
                        btn_class: '--disabled'
                    })
                }
            }

            getCartAddresses = async() => {
                const cartAddresses = await this.storeCart.getCartAddresses();

                this.setState({
                    cartAddresses: cartAddresses
                })
            }

            updateCartAddresses = async() => {
                this.storeCart.updateCartAddresses();
            }

            updateCartData = () => {
                //const email = 'h1nsl01@gmail.com';
                //const email = this.storeAuth.user.email;

                //console.log('SbC email', this.storeCart.cartId, this.storeAuth.user.email)
                //this.storeCart.saveCartData(email);
            }

            navigatieToPage = (page) => {              
                let url = "/";
                let origin = this.props.location.pathname;

                if (page !== 'home') {
                    url = "/" + page;
                    origin = origin.substring(1);
                } 

                if (page === 'login') {
                   this.storeGeneral.setOrigin(origin);
                } 
                
                this.props.navigate(url);
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        title = {'Add order details'}
                    >   
                        <React.Fragment>
                            {/* {this.state.blocks && this.state.blocks.length > 0 &&
                                <div
                                    className = "wrapper-content"
                                >
                                    {this.state.blocks && this.state.blocks.map((block, i) => (
                                        <div
                                            key = {i}
                                        >
                                            <p
                                                dangerouslySetInnerHTML={{ __html: block.textEN}}
                                            />
                                        </div>
                                    ))}
                                </div>
                            } */}

                            <div
                                className = "wrapper-content"
                            >
                                <p>
                                    {this.storeAuth.isLoggedin &&
                                        <React.Fragment>
                                            Changing your profile or address below will also affect your account details.<br/>
                                        </React.Fragment>
                                    }
                                    Changing the invoice or shipping country will affect the shipping costs.
                                </p>
                                <p>
                                    {!this.storeAuth.isLoggedin &&
                                        <React.Fragment>
                                            Existing customers can&nbsp;
                                            <span
                                                className = "--link --link-inline"
                                                onClick = {() => this.navigatieToPage('login')}
                                            >
                                                Login
                                            </span> to retrieve their details.
                                        </React.Fragment>
                                    }
                                </p>
                            </div> 

                            <div
                                className = "wrapper-content"
                            >
                                {this.state.hasCountries === true &&
                                    <FormCheckout
                                        user = {this.state.user}
                                        addresses = {this.state.cartAddresses}
                                        formIsValid = {this.formIsValid}
                                        invoiceCountryCode = {this.storeCart.invoiceingCountryCode}
                                        shippingCountryCode = {this.storeCart.shippingCountryCode}
                                    />
                                }
                            </div>       
                            
                            <div
                                className = "wrapper-content"
                            >
                                <h1>Review your order</h1>    
                            </div>

                            <CartView
                                view = {'checkout'}
                                editable = {false}
                            />          

                            <div
                                className = "wrapper-content"
                            >
                                <div className="form-row form-row--buttons">
                                    <button 
                                        className={"btn btn--secundary " + this.state.btn_class} 
                                        onClick={(e) => this.gotoPage('cart')}
                                    >
                                        {i18n.t("button.goback_cart")}
                                    </button>
                                    {this.state.formIsValid
                                    ?   <button 
                                            className={"btn btn--primary --link " + this.state.btn_class} 
                                            onClick={(e) => (
                                                this.updateCartAddresses(),
                                                //this.updateCartData(),
                                                this.gotoPage('payment')
                                            )}
                                        >
                                            {i18n.t("button.goto_payment")}
                                        </button>
                                    :   <button 
                                            className={"btn btn--primary " + this.state.btn_class}
                                        >
                                            {i18n.t("button.goto_payment")}
                                        </button>
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Checkout));
