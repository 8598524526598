import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import Template from '../templates/template';
import NavTabs from '../components/grid/nav-tabs';
import OrderRow from '../components/shop/order-row';
import OrderFilter from '../components/shop/order-filter';

const MyOrders = inject("stores") (
    observer (
        class MyOrders extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storePageContent = this.props.stores.storePageContent;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrders = this.props.stores.storeOrders;

                this.state = {
                   title: 'My account'
                }
            }

            componentDidMount = async() => {
                const page = "account";
                const title = await this.storePageContent.getPageTitle(page);

                const accountId = '14252';
                const orders = await this.storeOrders.getOrders('all');

                this.setActiveTab('orders');
            }

            componentDidUpdate = async() => {

            }

            componentWillUnmount = () => {
                this.setActiveTab('profile');
            }

            setActiveTab = (value) => {
                this.storeUi.setActiveTab(value);
            }

            getUser = async() => {
                const id = this.storeAuth.user.id;
                const email = this.storeAuth.user.email;
                const user = await this.storeUser.getUserData(id, email);

                return user;
            }

            render() {
                return (                  
                    <Template
                        type = 'default'
                        title = {this.state.title && this.state.title}
                    >   
                        <React.Fragment>
                            <NavTabs

                            />

                            <div
                                className = "wrapper-content"
                            >
                                {this.state.blocks && this.state.blocks.map((block, i) => (
                                    <div
                                        key = {i}
                                    >
                                        <p
                                             dangerouslySetInnerHTML={{ __html: block.textEN}}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div
                                className = "wrapper-content"
                            >
                                <OrderFilter
                                    
                                />
                                    
                                {this.storeOrders.listOrders && this.storeOrders.listOrders.length > 0 && this.storeOrders.listOrders.map((order, i) => (
                                    <OrderRow
                                        orderId = {order.orderId}
                                        order = {order}
                                    />
                                ))}
                                {(!this.storeOrders.listOrders || this.storeOrders.listOrders.length === 0) &&
                                    <span>You don't have any orders yet</span>
                                }
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(MyOrders));
