import { observable, computed, action } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import countries from "i18n-iso-countries";
import * as settings from '../communicator/settings';
import * as sort from '../utilities/sort';
import * as tools from '../communicator/tools';

class StoreGeneral {
    app_interface = { 
        loader: false,
        feedback_loader: ''
    }
    language = 'EN'
    listSettings = []
    listCountries = []
    origin = ''
    
    constructor() {
        this.getSettings();
        this.getCountries();
    }

    startLoader() {
        this.app_interface.loader = true;
        this.app_interface.feedback_loader = 'We zoeken een resultaat voor je ...';
    }

    stopLoader() {
        this.app_interface.loader = false;
        this.app_interface.feedback_loader = 'Er zijn geen resultaten gevonden.';
    }  

    async getCountries() {
        let countries;
        let listCountries;

        try {
            const returnData = await tools.getCountries();
            runInAction(() => {
                countries = returnData.data;
                this.listCountries = this.translateCountries(countries);

                let country = {
                    id: 0,
                    code: '00',
                    value: '00',
                    name: 'Select a country',
                    displayValue: 'Select a country' 
                }
                this.listCountries.unshift(country);

                return countries;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return countries;
    }
       
    async getSettings() {
        let listSettings;
        try {
            const returnData = await settings.getSettings();
            runInAction(() => {
                listSettings = returnData.data;
                listSettings.map(setting => {
                    this.listSettings[setting.SettingName] = setting.SettingValue;
                })
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    translateCountries(listCountries) {
        //SbC:: get country translations
        countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

        listCountries.map((country, i) => {
            //SbC:: missing values for AN and CS
            switch (country.code) {
                case 'AN':  
                    listCountries[i].displayValue = 'Nederlandse Antillen';
                    break;
                case 'CS':  
                    listCountries[i].displayValue = 'Servie en Montenegro';
                    break;
                default:    
                    listCountries[i].displayValue = countries.getName(country.code, "en");
            }
        })

        listCountries = listCountries
            .sort(sort.compareValues('displayValue', 'asc'))

        return listCountries;
    }

    setOrigin(value) {
        this.origin = value;
    }
}

export default StoreGeneral;