import { observable, computed, action } from "mobx";

import StoreAuth from './store-auth';
import StoreGeneral from './store-general';
import StoreUi from './store-ui';
import StorePageContent from './store-pagecontent';
import StoreProducts from './store-products';
import StoreDiscount from './store-discount';
import StoreUser from './store-user';
import StoreForms from './store-forms';
import StoreCart from './store-cart';
import StoreOrders from './store-orders';
import StoreShipping from './store-shipping';
import StoreVat from './store-vat';

class StoreRoot {
    constructor() {
        this.storeGeneral = new StoreGeneral(this);
        this.storeAuth = new StoreAuth(this);
        this.storeUi = new StoreUi(this);
        this.storePageContent = new StorePageContent(this);
        this.storeProducts = new StoreProducts(this);
        this.storeDiscount = new StoreDiscount(this);
        this.storeCart = new StoreCart(this);
        this.storeUser = new StoreUser(this);
        this.storeForms = new StoreForms(this);
        this.storeOrders = new StoreOrders(this);
        this.storeShipping = new StoreShipping(this);
        this.storeVat = new StoreVat(this);
    }
}

export default StoreRoot;