import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";
import { withTranslation } from 'react-i18next';

import * as formats from '../../utilities/formats';

const OrderItem = inject("stores") (
    observer (
        class OrderItem extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_PUBLIC;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeOrders = this.props.stores.storeOrders;

                this.state = {
                }
            }

            componentDidMount = async() => {

            }
            
            componentDidUpdate = (prevProps) => {

            }

            calcTotal = () => {
                let total = 0;
                
                total = this.props.item.priceIncl * this.props.item.amount * ((100 - this.props.item.discount) / 100);
                total = formats.displayPrice(total);

                return total;
            }

            render() {
                return (                  
                    <div
                        className = "wrapper-order__item"
                    >
                        <span
                            className = "item__description"
                            dangerouslySetInnerHTML={{ __html: this.props.item.description}} 
                        />
                        <span
                            className = "item__amount"
                        >
                            {this.props.item.amount}
                        </span>
                        <span
                            className = "item__amount"
                        >
                            {this.props.item.priceIncl}
                        </span>
                        <span
                            className = "item__amount"
                        >
                            {this.props.item.discount > 0 && (this.props.item.discount + '%')}
                        </span>
                        <span
                            className = "item__amount"
                        >
                            {this.calcTotal()}
                        </span>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(OrderItem));
