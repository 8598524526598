import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

const ContentBankTransfer = inject("stores") (
    observer (
        class ContentBankTransfer extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    lang: 'EN'
                }
            }

            componentDidMount = async() => {
                
            }
            
            componentDidUpdate = () => {
               
            }

            render() {
                return (                  
                    <div
                        className = "wrapper-bankdetails"
                    >
                        {this.state.lang !== 'NL' &&
                        <React.Fragment>
                            <h3>From within the Netherlands</h3>
                            <div className = "payment-info">
                                <ul className = "payment-info__list">
                                    <li className="payment-info__column">
                                        <strong>Rabobank Zeist</strong><br/>
                                        Account nr:<br/>NL56 RABO 0357 5205 80
                                    </li>
                                    <li className="payment-info__column">
                                        or: <strong>Triodosbank Zeist</strong><br/>
                                        Account nr:<br/>NL35 TRIO 0391 1028 26
                                    </li>
                                </ul>
                                <div>
                                    in the name of:<br/>
                                    Belfra Publishers for Success bv<br/>
                                    Dolderseweg 49<br/>
                                    3734 BB Den Dolder<br/>
                                    with reference "Order nr. <strong>{this.props.orderId}</strong>"
                                </div>
                                
                            </div>
                            <h3>From outside the Netherlands</h3>
                            <div className = "payment-info">
                                <ul className = "payment-info__list">
                                    <li className="payment-info__column">
                                        <b>From Belgium</b><br/>
                                        BNP Paribas Fortis<br/>
                                        Accountnr:<br/>BE71 0016 6035 5969<br/>
                                        Swift-adres (BIC#): GEBABEBB
                                    </li>
                                    <li className="payment-info__column">
                                        <b>From other countries:</b><br/>
                                        Bank: Rabobank<br/>
                                        IBAN:<br/>NL56 RABO 0357 5205 80<br/>
                                        Swift-adres (BIC#): RABONL2U
                                    </li>
                                </ul>
                                <div>
                                    in the name of:<br/>
                                    Belfra Publishers for Success bv<br/>
                                    Dolderseweg 49<br/>
                                    3734 BB Den Dolder<br/>
                                    with reference "Order nr. <strong>{this.props.orderId}</strong>"
                                </div>
                            </div>
                        </React.Fragment>
                        }

                        {this.state.lang === 'NL' &&
                        <React.Fragment>
                            <span className="feedback__title">Je hebt gekozen voor handmatige overschijving.</span>
                            <p>Maak het totaalbedrag van <span className="amount amount--inline">{this.props.totalCosts}</span> van je bestelling over naar:</p>
                            <h3>Vanuit Nederland</h3>
                            <div className = "payment-info">
                                <ul className = "payment-info__list">
                                    <li className="payment-info__column">
                                        <strong>Rabobank te Zeist</strong><br/>
                                        Rekeningnr:<br/>NL56 RABO 0357 5205 80
                                    </li>
                                    <li className="payment-info__column">
                                        of: <strong>Triodosbank te Zeist</strong><br/>
                                        Rekeningnr:<br/>NL35 TRIO 0391 1028 26
                                    </li>
                                </ul>
                                <div>
                                    ten name van:<br/>
                                    Belfra Publishers for Success bv<br/>
                                    Dolderseweg 49<br/>
                                    3734 BB Den Dolder<br/>
                                    onder vermelding van "Ordernr. <strong>{this.props.orderId}</strong>"
                                </div>
                                
                            </div>
                            <h3>Vanuit het buitenland</h3>
                            <div className = "payment-info">
                                <ul className = "payment-info__list">
                                    <li className="payment-info__column">
                                        <b>Vanuit Belgi&euml;</b><br/>
                                        BNP Paribas Fortis<br/>
                                        Rekeningnr:<br/>BE71 0016 6035 5969<br/>
                                        Swift-address (BIC#): GEBABEBB
                                    </li>
                                    <li className="payment-info__column">
                                        <b>Vanuit andere landen:</b><br/>
                                        Bank: Rabobank<br/>
                                        IBAN:<br/>NL56 RABO 0357 5205 80<br/>
                                        Swift-address (BIC#): RABONL2U
                                    </li>
                                </ul>
                                <div>
                                    ten name van:<br/>
                                    Belfra Publishers for Success bv<br/>
                                    Dolderseweg 49<br/>
                                    3734 BB Den Dolder<br/>
                                    onder vermelding van "Ordernr. <strong>{this.props.orderId}</strong>"
                                </div>
                            </div>
                        </React.Fragment>
                        }
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(ContentBankTransfer));
