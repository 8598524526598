import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as orders from '../communicator/orders';
import * as payment from '../communicator/payment';

class StoreOrders {
    listSettings = {
        isNL: true
    }
    countryCode = 'NL'
    listOrders = []
    paymentMethods = []
    paymentIssuers = []

    orderData = {
        payment: {
            payment_method: {
                id: 'XX',
                title: 'IDEAL'
            },
            payment_issuer: {
                id: 99,
                code: '9999',
                title: ''  
            }
        }
    }

    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeGeneral = this.storeRoot.storeGeneral;
        this.storeAuth = this.storeRoot.storeAuth;
        this.storeCart = this.storeRoot.storeCart;

        this.getPaymentMethods();
        this.getPaymentIssuers();
    }

    async getPaymentMethods() {
        const bankTrans = {
            id: 'BANKTRANS',
            description: 'Bank transfer',
            displayValue: 'Bank transfer'
        }
        try {
            const returnData = await payment.getPaymentMethods();
            runInAction(() => {
                this.paymentMethods = returnData.data;

                this.paymentMethods.map((method, i) => {
                    this.paymentMethods[i].displayValue = this.paymentMethods[i].description;
                    this.paymentMethods[i].value = i;
                })

                //SbC check if handmatige overschijving is aanwezig
                const idx = this.paymentMethods
                    .findIndex((method) => method.id === 'BANKTRANS');
                if (idx === -1) {                  
                    this.paymentMethods.push(bankTrans);
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getPaymentIssuers() {
        try {
            const returnData = await payment.getPaymentIssuers();
            runInAction(() => {
                let paymentIssuers = returnData.data;

                paymentIssuers.map((country, i) => {
                    paymentIssuers[i].displayValue = paymentIssuers[i].description;
                    paymentIssuers[i].value = i;
                    this.paymentIssuers[i] = paymentIssuers[i];
                })
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    setPaymentData(name, value, display_name) {
        this.orderData.payment[name] = {
            id: value,
            title: display_name
        }
        if (name === 'payment_issuer') {
            this.orderData.payment[name] = {
                code: this.paymentIssuers[value].code
            }
        } 
        if (name === 'payment_method') {
            if (value !== 'IDEAL') {
                this.orderData.payment['payment_issuer'] = {
                    code: '9999'
                }
            }
        } 
    }

    getOrderId() {
        let orderId = 0;
        if (localStorage.getItem('ABANDA_ORDERID')) {
            let data = JSON.parse(localStorage.getItem('ABANDA_ORDERID'));
            if (data) {
                orderId = data;
            } 
        }

        return orderId;
    }

    async getOrderStatus(orderId) {
        let orderStatus;
        //const orderId = this.getOrderId();

        try {
            const returnData = await orders.getOrderStatus(orderId);
            runInAction(() => {
                const result = returnData.data;
                orderStatus = result[0].orderStatus;

                return orderStatus;
            })           
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return orderStatus;
    }

    async setOrderStatus(orderId, status) {
        let orderStatus;

        try {
            const returnData = await orders.setOrderStatus(orderId, status);
            runInAction(() => {
                orderStatus = returnData.data;
            })

            return orderStatus;
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return orderStatus;
    }

    async getOrders(filter) {
        const accountId = this.storeAuth.user.userId;
        let listOrders;

        try {
            const returnData = await orders.getOrders(accountId, filter);
            runInAction(() => {
                this.listOrders = returnData.data;
                listOrders = returnData.data;

                return listOrders;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return listOrders;
    }

    async getOrder(orderId) {
        let order;

        try {
            const returnData = await orders.getOrder(orderId);
            runInAction(() => {
                order = returnData.data;

                return order;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return order;
    }

    async getOrderTotals(orderId) {
        let order;

        try {
            const returnData = await orders.getOrderTotals(orderId);
            runInAction(() => {
                order = returnData.data;
                return order;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return order;
    }

    async getPaymentLink(orderId, data) {
        let urlMsp ='';
      
        try {
            const returnData = await payment.getPaymentLink(orderId, data);
            runInAction(() => {
                urlMsp = returnData.data;
                this.urlMsp = returnData.data;

                return urlMsp;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return urlMsp;
    }

    async submitOrder() {
        let orderId;

        const cartId = this.storeCart.cartId;

        try {
            const returnData = await orders.submitOrder(cartId);
            runInAction(() => {
                orderId = returnData.data;
                this.saveOrderId(orderId);

                return orderId;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return orderId;
    }

    async sendOrderConfirmation(orderId) {
        try {
            const returnData = await orders.sendOrderConfirmation(orderId);
            runInAction(() => {

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    saveOrderId(orderId) {
        localStorage.setItem('ABANDA_ORDERID', orderId)
    }

    removeOrderId() {
        localStorage.removeItem('ABANDA_ORDERID')
    }

    removeCartId() {
        //localStorage.removeItem('ABANDA_CARTID')
    }
}

decorate(StoreOrders, {
    listOrders: observable,
    paymentMethods: observable,
    paymentIssuers: observable,
    urlMsp: observable,
    setOrderStatus: action,
    getOrders: action
})

export default StoreOrders;