import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import Template from '../templates/template';
import FormRegisterWholesale from '../components/forms/form-register-wholesale';

const RegisterWholesale = inject("stores") (
    observer (
        class RegisterWholesale extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storePageContent = this.props.stores.storePageContent;
                this.storeUser = this.props.stores.storeUser;

                this.state = {
                    redirectISBN: false,
                    redirectPayment: false,
                    id_quote: 0,
                    hasCountries: false
                }
            }

            componentDidMount = async() => {
                let countries = [];

                if (this.storeGeneral.listCountries.length === 0) {
                    countries = await this.storeGeneral.getCountries();
                }

                const page = "wholesale";
                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);

                const user = await this.getUser();

                this.setState({
                    title: title,
                    blocks: blocks,
                    user: user,
                    hasCountries: true
                })
            }

            componentDidUpdate = (prevProps) => {

            }

            getUser = async() => {
                const id = this.storeAuth.user.id;
                const email = this.storeAuth.user.email;
                const user = await this.storeUser.getUserData(id, email);

                return user;
            }

            onNextAction = () => {
                const url = '/';
                this.storeUi.setMsgVerification(0);
                
                this.props.navigate(url);
            }

            render() {

                return (                  
                    <Template
                        type = 'default'
                        title = {this.state.title && this.state.title}
                    >   
                        <React.Fragment>
                            <div
                                className = "wrapper-content"
                            >
                                {this.state.blocks && this.state.blocks.map((block, i) => (
                                    <div
                                        key = {i}
                                    >
                                        <p
                                             dangerouslySetInnerHTML={{ __html: block.textEN}}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div
                                className = "wrapper-content"
                            >
                                {this.storeAuth.user.isApproved === 2  
                                    ?   <div className="form-fieldset form-fieldset--notification">
                                            Your application as wholesale reseller is pending.<br/>
                                            As soon as your application is approved, the appropriate discounts will show in the shop.
                                        </div>
                                    : null
                                }
                                
                                {(this.storeAuth.user.isApproved !== 2 && this.storeAuth.user.isApproved !== 1) &&
                                    this.state.hasCountries === true &&
                                        <FormRegisterWholesale
                                            user = {this.state.user}
                                            onNext = {this.onNextAction}
                                        />
                                }
                            </div>
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(RegisterWholesale));
