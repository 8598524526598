import { observable, computed, action } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as content from '../communicator/content';

class StorePageContent {

    blocks = []

    constructor() {

    }

    async getPageContent(section) {
        let blocks;
        try {
            const returnData = await content.getContentBlocks(section);
            runInAction(() => {
                blocks = returnData.data;
                this.blocks = blocks;
                return blocks;

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return blocks;
    }

    async getPageTitle(section) {
        let title;
        try {
            const returnData = await content.getContentTitle(section);
            runInAction(() => {
                title = returnData.data[0].titleEN;
                this.title = title;
                return title;

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return title;
    }

    async getFaqContent() {
        let faq;
        try {
            const returnData = await content.getContentFaq();
            runInAction(() => {
                faq = returnData.data;
                this.faq = faq;
                return faq;

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return faq;
    }

    async getPrivacyPolicyContent() {
        let pp;
        try {
            const returnData = await content.getContentPrivacyPolicy();
            runInAction(() => {
                pp = returnData.data;
                this.pp = pp;

                return pp;

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return pp;
    }
}

export default StorePageContent;