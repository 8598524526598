import * as apifetch from './fetch.js';

export async function getContentBlocks(section) {
    const urlEndpoint = '/v2/content/blocks/' + section;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getContentTitle(section) {
    const urlEndpoint = '/v2/content/title/' + section;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getContentFaq() {
    const urlEndpoint = '/v2/content/faq';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getContentPrivacyPolicy() {
    const urlEndpoint = '/v2/content/pp';

    return apifetch.authFetch(urlEndpoint, 'GET');
}