import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

const NavTabs = inject("stores") (
    observer (
        class NavTabs extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeOrders = this.props.stores.storeOrders;

                this.state = {

                }
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = () => {

            }

            setActiveTab = (value) => {
                this.storeUi.setActiveTab(value);

                if (value == 'orders') {
                    this.storeOrders.getOrders('all');
                }
            }

            render() {
                return (                  
                    <div
                        className = "wrapper-content__tabs"
                    >
                        <div
                            className = {"wrapper-content__tab" + (this.storeUi.activeTab === 'profile' ? " --active" : "")} 
                            onClick = {() => this.setActiveTab('profile')}
                        >
                            <Link to="/account"><span>My personal data</span></Link>
                        </div>
                        <div
                            className = {"wrapper-content__tab" + (this.storeUi.activeTab === 'orders' ? " --active" : "")} 
                            onClick = {() => this.setActiveTab('orders')}
                        >
                            <Link to="/my-orders"><span>My orders</span></Link>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(NavTabs));
