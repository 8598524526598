import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as tools from '../communicator/tools'

class StoreShipping {

    hasShippingAddress = false
    shippingCountryCode = ''
    
    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeAuth = this.storeRoot.storeAuth;
        this.storeGeneral = this.storeRoot.storeGeneral;
        this.storeUser = this.storeRoot.storeUser;
        this.storeCart = this.storeRoot.storeCart;
    }

    async calcShipping(cart) {
        let defaultShipping = 3.5;
        let defaultShippingAbroad = 15;
        let shipping = 0;
        
        let countryCode = this.getShippingCountryCode();
            
        let shippingAbroad = false;
        shippingAbroad = this.checkShippingCountry(countryCode);

        let weight = 0;
        weight = this.getWeight(cart);
        let packageType;
        packageType = this.getPackageType(shippingAbroad);

        if (!Object.is(weight, NaN) && !isNaN(weight) ) {
            if (shippingAbroad) {
                shipping = await this.getShippingCostsAbroad(weight);
            } else {
                shipping = await this.getShippingCosts(weight, packageType);
            }
        } else {
            if (shippingAbroad) {
                shipping = defaultShippingAbroad;
            } else {
                shipping = defaultShipping;
            }
        }

        if (countryCode === 'NL') {
            shipping = 0;
        }

        const vat = this.getShippingVAT(shipping);
        shipping = parseFloat(shipping) + parseFloat(vat);

        return shipping;
    }

    getShippingCountryCode() {      
        let countryCode;
        const defaultCountryCode = this.storeGeneral.listSettings.defaultCountryCode;

        if (this.hasShippingAddress) {
            countryCode = this.storeCart.shippingCountryCode;
        } else {
            countryCode = this.storeCart.invoiceCountryCode;
            if (countryCode === '') {
                if (this.storeAuth.user.isLoggedin) {
                    if (this.storeUser.user.invoiceCountryCode !== undefined) {
                        countryCode = this.storeUser.user.invoiceCountryCode;
                    } else {
                        countryCode = defaultCountryCode;
                    }
                } else {
                    countryCode = defaultCountryCode;
                }
            }
        }

        if (countryCode === '00' || countryCode === null || countryCode === undefined) {
            countryCode = defaultCountryCode;
        }

        return countryCode;
    }

    setShippingCountryCode = (value) => {
        this.shippingCountryCode = value;
    }

    setHasShippingAddress = (value) => {
        this.hasShippingAddress = value;
    }

    checkShippingCountry(countryCode) {
        const defaultCountryCode = this.storeGeneral.listSettings.defaultCountryCode;

        let shippingAbroad = false;

        if (countryCode) {
            shippingAbroad = (countryCode === 'NL')
                ?   false 
                :   true
            ;
        } else {
            shippingAbroad = (defaultCountryCode === 'NL')
                ?   false 
                :   true
            ;
        }

        return shippingAbroad;
    }

    getWeight(cart) {
        let weight = 0;
        const defaultWeightBox = 80;

        weight = this.calculateWeight(cart, defaultWeightBox);

        return weight;
    }

    getPackageType(shippingAbroad) {
        // SbC as_package: 1
        // SbC as_letter: 2

        let packageType = 2;
        let amount = 3;

        if (shippingAbroad) {
            packageType = 1;
        } else {
            if (amount > 1) {
                packageType = 1;
            }
        }

        return packageType;
    }

    getShippingCosts = async(weight, packageType) => {
        let shipping = 0;
        let shippingData;

        let returnData;
        try {           
            returnData = await tools.getShippingCosts(weight);
            shippingData = returnData.data[0];

            runInAction(async() => {
                if (shippingData) { 
                    shipping = shippingData.as_letter !== null && packageType !== 1
                    ?   shippingData.as_letter <= shippingData.as_package 
                        ?   shippingData.as_letter 
                        :   shippingData.as_package
                    :   shippingData.as_package
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }   

        return shipping;
    }

    getShippingCostsAbroad = async(weight) => {
        let shipping = 0;
        let surcharge = 0;

        const listCountries = this.storeGeneral.listCountries;
        const countryCode = this.getShippingCountryCode();

        let idx = -1;
        idx = listCountries.findIndex(country => country.code == countryCode);
      
        //SbC calculate weight surcharge if applicable
        if (idx !== 0) {
            shipping = listCountries[idx].base_shipping;
        
            if (listCountries[idx].surcharge_weight !== null && listCountries[idx].surcharge_weight !== '') {
                surcharge =  weight/1000 * parseFloat(listCountries[idx].surcharge_weight);
                shipping = parseFloat(shipping) + surcharge;
            }
        }

        return shipping;
    }

    getShippingVAT(shipping) {
        const vat = 21;
        const shippingVAT = shipping * (vat/100);

        return shippingVAT;
    }

    calculateShippingVat() {
        let vatPerc = 21;

        return vatPerc;
    }

    calculateWeight(cart, defaultWeightBox) {
        let weight = 0;
        let weightBox;
        let defaultWeight = this.storeGeneral.listSettings.defaultWeight;
        
        if (this.storeGeneral.listSettings["weightBox"] !== undefined) {
            weightBox = parseInt(this.storeGeneral.listSettings["weightBox"]);
        } else {
            weightBox = defaultWeightBox;
        }

        cart.map((item, i) => {
            if (!('weight' in item) || (item.weight === null || item.weight === '')) {
                weight = weight + item.amount * defaultWeight;
            } else {
                weight = weight + item.amount * parseInt(item.weight);
            }
        })
       
        weight = weight + weightBox;

        return weight;
    }
}

decorate(StoreShipping, {
    shippingCountryCode: observable,
    hasShippingAddress: observable,
    setShippingCountryCode: action,
    setHasShippingAddress: action
})

export default StoreShipping;