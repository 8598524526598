import * as apifetch from './fetch.js';

export async function getOrders(accountId, filter) {
    const urlEndpoint = '/v2/orders/' + accountId + '/list/' + filter;

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getOrder(orderId) {
    const urlEndpoint = '/v2/orders/' + orderId + '/items';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getOrderTotals(orderId) {
    const urlEndpoint = '/v2/orders/' + orderId + '/totals';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getOrderStatus(orderId) {
    const urlEndpoint = '/v2/orders/' + orderId + '/status';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function setOrderStatus(orderId, status) {
    const urlEndpoint = '/v2/orders/' + orderId + '/status';

    const postData = {
        status: status
    };

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function submitOrder(cartId) {
    const urlEndpoint = '/v2/orders/' + cartId + '/submit';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function sendOrderConfirmation(orderId) {
    const urlEndpoint = '/v2/orders/' + orderId + '/confirmation';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function addOrderLineShipping(order_line, id, action) {
    const urlEndpoint = '/v2/orders/' + id + '/shipping/add';
    
    const postData = order_line;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function deleteOrderLineShipping(id) {
    const urlEndpoint = '/v2/orders/' + id + '/shipping';

    return apifetch.sbFetch(urlEndpoint, 'DELETE');
}

export async function editOrderLineShipping(order_line, id, action) {
    const urlEndpoint = '/v2/orders/' + id + '/shipping/edit';
    
    const postData = order_line;

    return apifetch.sbFetch(urlEndpoint, 'PUT', postData);
}

export async function getOrderLineShipping(id) {
    const urlEndpoint = '/v2/orders/' + id + '/shipping';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}