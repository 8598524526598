import * as apifetch from './fetch.js';

export async function getSettings() {
    const urlEndpoint = '/v2/settings';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getLanguages() {
    const urlEndpoint = '/settings/getLanguages.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}
