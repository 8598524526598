import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { I18nextProvider } from "react-i18next";

import i18n from "./i18n";
import App from './app';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
import StoreRoot from './stores/store-root';

const storeRoot = new StoreRoot();

root.render(
    <React.Fragment>
        <I18nextProvider i18n={i18n}>
            <Provider stores={storeRoot}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </I18nextProvider>
    </React.Fragment>
);

reportWebVitals();
