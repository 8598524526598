import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";
import { withTranslation } from 'react-i18next';

import Template from '../../templates/template';
import CartRow from './cart-row';
import CartTotals from './cart-totals';

const CartView = inject("stores") (
    observer (
        class CartView extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    btn_class: '--active'
                }
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeAuth = this.props.stores.storeAuth;
                this.storePageContent = this.props.stores.storePageContent;
                this.storeUser = this.props.stores.storeUser;
                this.storeCart = this.props.stores.storeCart;
                this.storeShipping = this.props.stores.storeShipping;
            }

            componentDidMount = async() => {
                const accountId = this.storeAuth.user.userId;

                let cartId = this.storeCart.getLocalStorageId();
                if (cartId === 0) {
                    cartId = await this.storeCart.getCartId(accountId);
                }
                const cart = await this.storeCart.getCartItems(cartId);


                const page = "cart";
                const title = await this.storePageContent.getPageTitle(page);
                const blocks = await this.storePageContent.getPageContent(page);

                this.setState({
                    title: title,
                    blocks: blocks
                })
            }
            
            componentDidUpdate = (prevProps) => {

            }

            gotoPage = (page) => {
                const url = "/" + page;
                this.props.navigate(url);
            }

            render() {
                return (                  
                    <React.Fragment>
                        <div
                            className = "wrapper-content"
                        >
                            {(!this.storeCart.cart || (this.storeCart.cart && this.storeCart.cart.length === 0)) &&
                                <div>
                                    Your cart is still empty
                                </div>
                            }

                            {this.storeCart.cart && this.storeCart.cart.length !== 0 &&
                                <React.Fragment>
                                    {this.storeCart.cart.map((item) => (
                                        <CartRow
                                            item = {item}
                                            amount = {item.amount}
                                            editable = {this.props.editable}
                                        />
                                    ))}
                                    <CartTotals
                                        view = {this.props.view}
                                        nrItems = {this.storeCart.nrItems}
                                        hasShippingAddress = {this.storeShipping.hasShippingAddress}
                                        countryCode = {this.storeShipping.getShippingCountryCode()}
                                    />
                                </React.Fragment>
                            }
                        </div>

                        {this.props.view === 'shop' &&
                            <div
                                className = "wrapper-content"
                            >
                                <div className="form-row form-row--buttons">
                                    <button className={"btn btn--secundary " + this.state.btn_class} onClick={(e) => this.gotoPage('shop')}>{i18n.t("button.goback_shop")}</button>
                                    {this.storeCart.cart && this.storeCart.cart.length !== 0
                                    ?   <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.gotoPage('checkout')}>{i18n.t("button.goto_checkout")}</button>
                                    :   <button className={"btn btn--primary"}>{i18n.t("button.goto_checkout")}</button>
                                    }
                                </div>
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CartView));
